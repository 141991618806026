import * as React from "react";
import { Button, Flex, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { ProgressHeader } from '../../components/ProgressHeader/ProgressHeader';
import FaqPage from '../FaqPage/FaqPage';
import { useLocation } from 'react-router-dom';
import { globalConfig } from "../../configuration/config";
import { Step } from "../../types";

import '../../App.css';
import "./ThankYou.css";

import triangleIcon from "../../assets/pages/payment/error_triangle.svg";
import logoKdg from "../../assets/pages/thank-you/logo_kauf_dich_gluecklich.png";

interface DepositReceiptErrorModalState {
    isOpen: boolean;
    infoText: string;
}

const initialErrorModalState: DepositReceiptErrorModalState = {
    isOpen: false,
    infoText: "",
}

export const ThankYouPage: React.FC = () => {
    const [faqPage, setFaqPage] = React.useState<React.ReactElement | null>(null);
    const [modalState, setModalState] = React.useState<DepositReceiptErrorModalState>(initialErrorModalState);
    const { state } = useLocation();

    const transactionId = state?.transactionId ?? '';
    const showKdgToken = state?.showKdgToken ?? false;

    const switchToFaqPage = () => {
        const onClose = () => {
            setFaqPage(null);
        };
        setFaqPage(<FaqPage onClose={onClose} />);
    };

    const requestReceipt = () => {
        const url = globalConfig.get().apiUrl + "/deposit_receipt/" + transactionId; 

        fetch(url).then(response => {
            if (response.body === null || response.status !== 200) {
                switch (true) {
                    case response.status >= 500:
                        setModalState({ isOpen: true, infoText: "Hier ist etwas schief gelaufen, versuch es später noch einmal!" });
                        break;
                    case response.status === 404:
                        setModalState({ isOpen: true, infoText: `Für die Transaktionsnummer ${transactionId} konnte kein Pfandbelege gefunden werden. Bitte kontaktieren Sie unseren Kundenservice!` });
                        break;
                    case response.status === 403:
                        setModalState({ isOpen: true, infoText: "Dieser Downloadlink ist leider nicht mehr gültig. Bitte kontaktieren Sie unseren Kundenservice!" });
                        break;
                    default:
                        setModalState({ isOpen: true, infoText: "Hier ist etwas schief gelaufen, versuch es später noch einmal!" });
                        break;
                }
                return;
            }

            const reader = response.body.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    (function pump(): any {
                        return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            return pump();
                        });
                    })();
                },
            });

            new Response(stream).blob().then(blob => {
                window.open(URL.createObjectURL(blob), '_blank');
            });
        });
    };

    const backButton = <Button shape="round" className="btn-back" href="https://multiloop.com/retoure">Zurück</Button>;

    return <div className="ty-page">
        <div className="ty-header">
            <Flex>
                <ProgressHeader step={Step.THANK_YOU} onClickFaq={switchToFaqPage} />
            </Flex>
            <h1>Dein Beleg</h1>
            <Flex
                style={{
                    width: "90%",
                    fontSize: "1em",
                    marginLeft: "20px",
                }}
                gap="middle"
            >
                <span style={{ paddingLeft: "5px" }}>Transaktions-ID</span>
                <span style={{ fontWeight: "bold" }}>{transactionId.toUpperCase()}</span>
            </Flex>
            <Flex align="center" justify="flex-start" style={{ margin: "20px 0 0 20px", fontSize: "1.4em" }} gap="middle">
                <Button
                    shape="circle"
                    type="link"
                    style={{ background: "white" }}
                    target="_self"
                    onClick={requestReceipt}
                    download={true}
                >
                    <DownloadOutlined style={{ color: "black" }} />
                </Button>
                <span>Beleg speichern</span>
            </Flex>
        </div>
        {
            showKdgToken ? 
                <Flex align="center" justify="center">
                    <Flex
                        align="center"
                        justify="top"
                        className="kdg-body"
                        style={{
                            borderRadius: "15px",
                        }}
                        vertical={true}
                    >
                        <img
                            className="kdg-logo"
                            src={logoKdg}
                            alt="kauf dich glücklich logo"
                        />
                        <p className="kdg-text">
                            Zeige diese Bestätigungsseite unseren Mitarbeiter:innen im Kauf Dich Glücklich Store und erhalte als Dankeschön einen 5 € Gutschein! Dieser Gutschein ist innerhalb der nächsten 7 Tage einmalig einlösbar. Er gilt ab einem Einkaufswert von 25 € und in allen Kauf Dich Glücklich Filialen in Deiner Stadt. Er gilt nicht online und ist nicht übertragbar.
                        </p>
                        {backButton}
                    </Flex>
                </Flex>
                : 
                <Flex align="center" justify="center" style={{ marginTop: "100px", width: "100%", height: "100%" }}>
                    {backButton}
                </Flex>
        }
        {faqPage}
        <Modal
            className="ty-forbidden"
            title="Fehler"
            centered
            open={modalState.isOpen}
            footer=""
            onCancel={ () => {setModalState(initialErrorModalState); } }
        >
            <Flex vertical>
                <p>{modalState.infoText}</p>
                <Flex justify="space-around">
                    <img src={triangleIcon} alt="error icon" />
                </Flex>
            </Flex>
        </Modal>
    </div>
};
