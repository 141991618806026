import * as React from "react";
import { Flex } from "antd";
import "./DepositPaidOff.css";
import leafImage from "../../../assets/pages/scan/message/leaf.svg";
import { useNavigate } from "react-router-dom";

interface DepositPaidOffProps {
    transactionId: string;
    showKdgToken: boolean;
}

export const DepositPaidOff: React.FC<DepositPaidOffProps> = ({ transactionId, showKdgToken }) => {
    const navigate = useNavigate();
    setTimeout(() => navigate("/thanks", { state: { transactionId: transactionId, showKdgToken: showKdgToken } }), 4000);

    return <Flex
        justify='center'
        align='center'
        className='deposit-paid-off overlay'
    >
        <Flex
            justify='center'
            align='top'
            className='message'
        >
            <Flex
                justify='left'
                align='left'
                vertical={true}
            >
                <h1>Pfand erfolgreich ausgezahlt</h1>
                <Flex justify="space-between" align="flex-start">
                    <p>Gemeinsam mit dir halten wir die Box im Loop.</p>
                    <img src={leafImage} alt="" style={{}} />
                </Flex>
            </Flex>
        </Flex>
    </Flex>
}
