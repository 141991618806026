import * as React from "react";
import { Flex } from "antd";
import "./ScanContainerSuccess.css";

interface ScanContainerSuccessProps {
    timeoutCallback: () => void;
}

export const ScanContainerSuccess: React.FC<ScanContainerSuccessProps> = ({
    timeoutCallback,
}) => {
    setTimeout(() => { timeoutCallback(); }, 4000);

    return <Flex
        justify='center'
        align='center'
        className='success overlay'
    >
        <Flex
            justify='center'
            align='top'
            className='message'
        >
            <Flex
                justify='left'
                align='left'
                vertical={true}
            >
                <h1>Dein Scan war erfolgreich</h1>
                <p>Jetzt kannst du deine Box scannen</p>
            </Flex>
        </Flex>
    </Flex>;
}
