import * as React from "react";
import * as SDCCore from "scandit-web-datacapture-core";
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import { useEffect, useRef } from 'react';
import { globalConfig } from "../../../configuration/config";
import { BarcodeCapture, BarcodeCaptureOverlay, BarcodeCaptureSession } from "scandit-web-datacapture-barcode";
import { Logger } from "scandit-web-datacapture-core";
import Level = Logger.Level;

interface IScanditScannerProps {
    /** Function to be called when a new barcode is successfully scanned. */
    onScanCallback: (data: string) => Promise<void>;
    isScanning: boolean;
}

/* eslint-disable react-hooks/exhaustive-deps */
const ScanditScanner: React.FC<IScanditScannerProps> = ({ onScanCallback, isScanning }: IScanditScannerProps) => {
    const scannerContainerRef = useRef<HTMLDivElement | null>(null);
    let context: SDCCore.DataCaptureContext | undefined;
    let view: SDCCore.DataCaptureView | undefined;
    let barcodeCapture: BarcodeCapture | undefined;
    let barcodeCaptureOverlay: BarcodeCaptureOverlay | undefined;
    const [bcCapture, setBcCapture] = React.useState<BarcodeCapture | undefined>(undefined);

    useEffect(() => {
        if (bcCapture !== undefined) {
            bcCapture.setEnabled(isScanning);
        }
    }, [isScanning]);

    useEffect(() => {
        async function runScanner() {
            await SDCCore.configure({
                licenseKey: globalConfig.get().scanditLicenseKey,
                libraryLocation: new URL("/scandit-lib/engine", document.baseURI).toString(),
                // libraryLocation: new URL("https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/", document.baseURI).toString(),
                // libraryLocation: new URL("/library/engine", document.baseURI).toString(),
                moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
                logLevel: Level.Quiet,
            });

            context = await SDCCore.DataCaptureContext.create();

            const camera = SDCCore.Camera.default;
            await context.setFrameSource(camera);

            const settings = new SDCBarcode.BarcodeCaptureSettings();
            settings.enableSymbologies([
                SDCBarcode.Symbology.QR,
            ]);
            // use negative codeDuplicateFilter, so that a code is only reported once
            settings.codeDuplicateFilter = -1

            barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(context, settings);
            setBcCapture(barcodeCapture);

            const feedback = SDCBarcode.BarcodeCaptureFeedback.default;
            feedback.success = new SDCCore.Feedback(
                SDCCore.Vibration.defaultVibration,
                SDCCore.Sound.defaultSound
            );
            await barcodeCapture.setFeedback(feedback);

            barcodeCapture.addListener({ didScan: didScanListener });

            view = await SDCCore.DataCaptureView.forContext(context);

            view.connectToElement(scannerContainerRef.current!);
            view.addControl(new SDCCore.CameraSwitchControl());

            barcodeCaptureOverlay =
                await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
                    barcodeCapture,
                    view,
                    SDCBarcode.BarcodeCaptureOverlayStyle.Frame
                );

            await barcodeCaptureOverlay.setViewfinder(SDCCore.NoViewfinder);

            await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
        }

        const didScanListener = async (barcodeCapture: BarcodeCapture, session: BarcodeCaptureSession) => {
            await barcodeCapture.setEnabled(false);
            const barcode = session.newlyRecognizedBarcodes[0];
            if (barcode.data !== null) {
                await onScanCallback(barcode.data);
                return;
            }
            await barcodeCapture.setEnabled(true);
        }

        /**
         *   // Handler to catch and alert any errors occurring during the scanning process
         *   onScanError={(error: any) => {
         *     alert(error);
         *     console.log(error);
         *   }}
         */
        runScanner().catch((error) => {
            console.error(error);
            // alert('Scan Error: ' + error);
            // TODO follow-up: show error message/card to user
        });

        return () => {
            console.log('scanner cleanup call');

            context?.frameSource?.switchToDesiredState(SDCCore.FrameSourceState.Off)
            context?.dispose();
            context?.removeAllModes();

            if (barcodeCaptureOverlay) {
                barcodeCaptureOverlay.setViewfinder(null).then();
                view?.removeOverlay(barcodeCaptureOverlay);
            }
            // if (cameraSwitchControl) {
            //     view?.removeControl(cameraSwitchControl);
            //     cameraSwitchControl = undefined;
            // }
            view?.detachFromElement();
            barcodeCapture?.removeListener({
                didScan: didScanListener,
            })
            barcodeCapture = undefined;
            context = undefined;
            view = undefined;
            // settings = undefined;
            // camera = undefined;
            // host?.remove();
            // host = undefined;
        };
    }, []);

    return (
        <div ref={scannerContainerRef} />
    );
};

export default ScanditScanner;
