import * as React from "react";

import { openMapApp } from "../../../util/functions";
import { ReactComponent as MarkerSvg } from '../../../assets/button-icons/marker.svg';
import { Button } from "antd";
import { CustomIconComponentProps } from "@ant-design/icons/es/components/Icon";
import Icon from '@ant-design/icons';

/**
 * Interface representing the props structure.
 */
interface IOpenInNavAppButtonProps {
  /**
   * The geographical coordinates (latitude and longitude)
   * that the navigation app should open to.
   */
  location: google.maps.LatLngLiteral;
}

const OpenInNavAppButton: React.FC<IOpenInNavAppButtonProps> = ({
    location,
}: IOpenInNavAppButtonProps) => {

    const MarkerIcon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={MarkerSvg} {...props} />
    );

    return (
        <Button
            shape="round"
            block
            icon={<MarkerIcon style={{ fontSize: '24px', margin: 0, position: 'absolute', right: 16, top: 16 }}/>}
            onClick={() => openMapApp(location)}
            style={{ padding: 12, height: 56, backgroundColor: 'rgba(255, 129, 197, 1)', position: 'relative' }}
        >
            Route finden
        </Button>
    );
};

export default OpenInNavAppButton;
