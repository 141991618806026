export interface PointOfReturn {
    id: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postalCode: string,
    city: string;
    locationDesc: string;
    latitude: number;
    longitude: number;
}

export enum PaymentMethod {
    PAYPAL,
    BANKTRANSFER,
}

export interface PaymentData {
    porId: string;
    rpIds: string[];
}

export interface Location {
    name: string;
}

export interface NewPointOfReturn {
    id: string;
    location: Location;
}

export interface Deposit {
    amount: string;
    currency: string;
}

export interface ReusablePackaging {
    id: string;
    deposit: Deposit;
}

export enum Step {
    SCAN_CONTAINER = 0,
    SCAN_BOX = 1,
    PAYMENT = 2,
    THANK_YOU = 3,
}

export enum ScanMessage {
    NONE,
    SCAN_CONTAINER_SUCCESS,
    SCAN_FAILURE,
    SCAN_WAIT,
}

export interface Message{
    messageType: ScanMessage;
    messageText: string;
    messageButtonText: string;
    navigateToPayment: null | (() => void);
}
/**
 * Represents the main type of asset in the system, differentiating between the actual product packaging and return facilities (return stations and containers).
 *
 * por - PointOfReturn
 * rp - ReusablePackaging
 *
 */
export enum AssetType {
    PointOfReturn = 'por',
    ReusablePackaging = 'rp',
}

/**
 * Enumeration of potential states an asset can hold
 */
export enum AssetState {
    active = 0,
    inactive = 1,
}

export enum RefundType {
    paypal = 'paypal',
    bank_transfer = 'bank_transfer',
}

/**
 * Enumeration distinguishing between different types of returnable assets
 */
export enum ReturnableAssetType {
    package = 855110000,
    container = 855110001,
}

export interface PaymentErrorModalState {
    isOpen: boolean;
    infoText: string;
    showCustomerSupport: boolean;
}
/**
 * Enumeration representing the various statuses a returnable asset can have during its lifecycle, indicating its current condition or location in a workflow.
 */
export enum ReturnableAssetStatus {
    preliminary = 855110001,
    warehouse = 855110002,
    circulation = 855110003,
    return = 855110004,
    missing = 855110005,
    destroyed = 855110006,
}

/**
 * Enumeration representing the status of a Point of Receipt (PoR).
 *
 * @remarks This appears to overlap with the AssetState enumeration, and its necessity should be reviewed.
 */
export enum PoRStatus { // TODO - review necessity
    active = 1,
    inactive = 2,
}

// Re-exporting LatLngLiteral type from Google Maps library for consistent usage throughout our application
export type LatLngLiteral = google.maps.LatLngLiteral;

/**
 * Type representing a return station with detailed address information and a unique identifier.
 *
 * @field id - A unique identifier for the return station (GRAI code)
 * @field name - The name of the return station
 * @field addressLine1 - The primary address line of the return station
 * @field addressLine2 - The secondary address line of the return station (if any)
 * @field addressLine3 - The tertiary address line of the return station (if any)
 * @field city - The city in which the return station is located
 * @field postalCode - The postal code of the return station's location
 * @field description - A description providing more details about the return station
 * @field location - The geographical coordinates (latitude and longitude) representing the location of the return station
 */
export type ReturnStation = {
    id: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    postalCode: string;
    description: string;
    location: LatLngLiteral;
};

/**
 * Type representing a deposit receipt with detailed information on the return process.
 *
 * @field id - A unique identifier for the deposit receipt
 * @field pointOfReturn - The description and address of the return station
 * @field numberOfBoxes - The number of boxes that have been returned
 * @field depositAmount - The deposit amount to cash out
 * @field containedTax - The tax contained in the deposit amount
 * @field createdAt - The timestamp this deposit receipt was created
 */
export type DepositReceipt = {
    id: string;
    pointOfReturn: string;
    numberOfBoxes: number;
    depositAmount: number;
    currency: string;
    containedTax: number;
    createdAt: number|null;
    scannedBoxIds: string[];
};
