import './PrivacyPage.css';
import * as React from "react";

const PrivacyPage: React.FC = () => (
    <>

        <div className="privacy">
            <header>
                <h1>Datenschutzerkl&auml;rung</h1>
            </header>
            <p>
                Vielen Dank f&uuml;r Ihren Besuch auf der Website der Multiloop GmbH. Im Folgenden finden Sie die
                Datenschutzerkl&auml;rung, welche Sie ausf&uuml;hrlich &uuml;ber die Verarbeitung Ihrer personenbezogenen Daten auf
                der Website www.multiloop.com informiert.<br/>
                <br/>
                Da wir als Verantwortlicher gro&szlig;en Wert auf die vertrauliche und sichere Verarbeitung Ihrer
                personenbezogenen Daten legen, haben wir umfangreiche technische und auch organisatorische Ma&szlig;nahmen
                ergriffen, um den Anforderungen der Datenschutz Grundverordnung (DS-GVO), des
                Bundesdatenschutzgesetzes (BDSG-neu), des Telekommunikation-Telemedien-Datenschutz-Gesetzes (TTDSG)
                und weiterer datenschutzrechtlicher Vorgaben gerecht zu werden.<br/>
                <br/>
                Auf dieser Website erfolgt keine Weitergabe Ihrer personenbezogenen Daten an Dritte, vorausgesetzt
                dies ist nicht zweckbezogen erforderlich oder Sie haben dem ausdr&uuml;cklich zugestimmt. Die
                Verarbeitung, der von Ihnen &uuml;ber die Website erhobenen Daten, findet ausschlie&szlig;lich zu den in dieser
                Datenschutzerkl&auml;rung erl&auml;uterten Zwecken statt.
            </p>
            <h2>1. Kontaktdaten Verantwortlicher & Datenschutzbeauftragter</h2>
            <p>
                Verantwortlicher gem&auml;&szlig; Art. 4 Nr. 7 DS-GVO f&uuml;r die Verarbeitung Ihrer personenbezogenen Daten ist
                die:<br/>
                <br/>
                Multiloop GmbH<br/>
                Zugspitzstr. 15<br/>
                DE-82046 Pullach<br/>
                <br/>
                Telefon: +49 (0)89 215 4151 40<br/>
                Telefax: +49 (0)89 215 4151 49<br/>
                E-Mail: info(a)multiloop.com<br/>
                <br/>
                Weitere Angaben zu unserem Unternehmen entnehmen Sie bitte den Impressumsangaben auf unserer
                Internetseite
            </p>
            <h2>2. Logfiles beim Besuch der Website</h2>
            <p>
                Beim &Ouml;ffnen dieser Website sendet Ihr Browser automatisch Daten an den Server der Website. Diese
                Daten sind teilweise datenschutzrechtlich relevant und werden zeitlich begrenzt in einer
                Protokolldatei (Logfile) gespeichert. Die Logfiles sind technisch erforderlich, damit Ihnen die
                Website angezeigt werden kann. Eine weitere Speicherung der Logfiles, die &uuml;ber Ihren Besuch der
                Website hinausgeht, erfolgt um die Funktionalit&auml;t der Website und die Sicherheit der
                informationstechnischen Systeme zu gew&auml;hrleisten. Folgende Daten werden im Rahmen der Logfiles
                erhoben:<br/>
                <br/>
                Datum und Uhrzeit des Zugriffs<br/>
                <br/>
                IP-Adresse &uuml;ber die der Zugriff erfolgt<br/>
                <br/>
                Hostname des zugreifenden Rechners<br/>
                <br/>
                Erfolg oder Misserfolg des Seitenaufrufs<br/>
                <br/>
                Verwendeter Browser und Betriebssystem<br/>
                <br/>
                &Uuml;bertragene Datenmenge<br/>
                <br/>
                Website von welcher der Besucher kommt (Referrer URL)[IF1]<br/>
                <br/>
                Wie Sie der Auflistung entnehmen k&ouml;nnen, wird mit Ihrer Nutzung der Website unter anderem die
                IP-Adresse des von Ihnen genutzten Rechners aufgezeichnet. &Uuml;ber die IP-Adresse kann es unter
                Umst&auml;nden m&ouml;glich sein, Nutzerinnen und Nutzer zu identifizieren. Eine hierauf gerichtete Auswertung
                der erfassten IP-Adressen findet, sofern dies nicht f&uuml;r Zwecke der Strafverfolgung erforderlich ist,
                jedoch nicht statt.<br/>
                <br/>
                Auf welcher Rechtsgrundlage werden diese Daten verarbeitet und wie lange werden sie
                gespeichert?<br/>
                <br/>
                Die Daten werden auf der Grundlage des berechtigten Interesses gem&auml;&szlig; Art. 6 Abs. 1 Satz 1 lit. f)
                DS-GVO verarbeitet. Die Speicherdauer der Logfiles betr&auml;gt [90] Tage nach Aufruf der Website, im
                Anschluss werden die Daten automatisiert gel&ouml;scht.<br/>
                <br/>
                Gibt es neben dem Verantwortlichen weitere Empf&auml;nger der Daten?<br/>
                <br/>
                Sowohl der Hoster, als auch der Webdesigner haben Zugriff auf die Logfiles. In beiden F&auml;llen erfolgt
                dieser Zugriff im Rahmen einer Auftragsverarbeitung gem&auml;&szlig; Art. 28 Abs. 3 DS-GVO.
            </p>
            <h2>3. Kontaktaufnahme</h2>
            <p>
                Unsere Website verf&uuml;gt nicht &uuml;ber ein Kontaktformular. Wenn Sie mit uns in Kontakt treten
                m&ouml;chten,
                finden Sie an verschiedenen Stellen unseres Internetauftritts entsprechende Kontaktdaten, welche
                Ihnen eine Kontaktaufnahme per E-Mail, Telefon oder Post erm&ouml;glichen. Welche Daten zu Ihrer
                Person
                Sie uns im Rahmen des Kontaktes mitteilen, obliegt selbstverst&auml;ndlich Ihnen. Wir verarbeiten
                ausschlie&szlig;lich die personenbezogenen Daten, die Zweckgebunden erforderlich sind.<br/>
                <br/>
                Auf welcher Rechtsgrundlage werden diese Daten verarbeitet und wie lange werden sie
                gespeichert?<br/>
                <br/>
                Ihre Daten, die Sie uns bei der Kontaktaufnahme zukommen lassen, werden auf der Grundlage Ihrer
                Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 Satz 1 lit. a) DS-GVO verarbeitet. Wenn es sich hierbei
                um Kontakt-/Kommunikationsdaten handelt, willigen Sie ebenfalls ein, dass wir auch Sie auf diesem
                Wege
                kontaktieren, um Ihr Anliegen zu bearbeiten. Die in diesem Zusammenhang erhobenen personenbezogenen
                Daten werden unverz&uuml;glich gel&ouml;scht, sobald Ihre Anfrage abschlie&szlig;end bearbeite ist
                und keine Gr&uuml;nde f&uuml;r eine weitere Aufbewahrung gegeben sind (z. B. Rechenschaftspflicht,
                Vertragsabschluss, etc.).<br/>
                <br/>
                Gibt es neben dem Verantwortlichen weitere Empf&auml;nger der Daten?<br/>
                <br/>
                Ggf. erlangen von uns beauftragte IT- und Softwaredienstleister Einsicht in Ihre Daten, welche in
                unseren IT-Systemen zur Bearbeitung Ihres Anliegens gespeichert sind. Diese Zugriffe erfolgen auf
                Basis eines Vertrages zur Auftragsverarbeitung gem&auml;&szlig; Art. 28 Abs. 3 DS-GVO.]
            </p>
            <h2>4. Links zu anderen Websites</h2>
            <p>
                Unsere Website enth&auml;lt Verlinkungen zu Websites Dritter. Wir haben keinen Einfluss auf die
                Verarbeitung Ihrer Daten auf diesen Drittanbieterseiten. F&uuml;r die dort stattfinden Verarbeitungen,
                die Datenschutzvorkehrungen und auch die publizierten Inhalte, sind die Anbieter dieser Websites
                verantwortlich.
            </p>
            <h2>5. Sicherheit</h2>
            <p>
                Zum Schutz Ihrer Daten, welche &uuml;ber unsere Website verarbeitet werden, haben wir zahlreiche
                technische und organisatorische Ma&szlig;nahmen (TOMs) vorgenommen. Hierzu z&auml;hlen beispielsweise die
                Vertraulichkeit, Integrit&auml;t, Verf&uuml;gbarkeit und Belastbarkeit Ihrer Daten. Die von uns getroffenen
                Sicherheitsma&szlig;nahmen werden regelm&auml;&szlig;ig kontrolliert und dem aktuellen Stand der Technik angepasst.
            </p>
            <h2>6. Betroffenenrechte</h2>
            <p>
                Gibt es neben dem Verantwortlichen weitere Empf&auml;nger der Daten?<br/>
                <br/>
                Eine &Uuml;bermittlung Ihres Auskunftsersuchens an Dritte ist nicht vorgesehen. Sollte diese aus
                wichtigem Grund trotzdem erforderlich sein, werden Sie selbstverst&auml;ndlich hier&uuml;ber
                informiert.<br/>
                <br/>
                Die Betroffenenrechte gem&auml;&szlig; des Datenschutzgesetzes, beschreiben die Rechte und
                Freiheiten der von einer Datenverarbeitung betroffenen Personen nach Art. 12 ff. DS-GVO. Au&szlig;erdem
                sch&uuml;tzen sie die informationelle Selbstbestimmung, welche sich aus dem Grundgesetz ergibt und dienen
                der Information und Transparenz.
            </p>
            <h2>6.1. Auskunftsanspruch</h2>
            <p>
                Sie haben zufolge des Art. 15 DS-GVO das Recht, Auskunft &uuml;ber die zu Ihrer Person gespeicherten
                personenbezogenen Daten zu erhalten. Ihrem Wunsch nach Auskunft kommen wir gerne nach. Bitte richten
                Sie Ihren Auskunftsanspruch schriftlich an unseren Datenschutzbeauftragten.<br/>
                <br/>
                Sollten Sie von Ihrem Auskunftsanspruch Gebrauch machen, so speichern wir Ihr Ersuchen und die
                Antwort darauf, um unserer Rechenschaftspflicht nachzukommen.<br/>
                <br/>
                Auf welcher Rechtsgrundlage werden diese Daten verarbeitet und wie lange werden sie
                gespeichert?<br/>
                <br/>
                Im Rahmen eines Auskunftsersuchens, werden Ihre Daten auf der Grundlage einer rechtlichen
                Verpflichtung gem&auml;&szlig; Art. 6 Abs. 1 Satz 1 lit. c) DS-GVO verarbeitet. Die Speicherdauer
                eines Auskunftsersuchens und der daraus resultierenden Kommunikation betr&auml;gt [3] Jahre nach Abschluss
                des Auskunftsverfahrens. Diese Aufbewahrungsfrist ergibt sich aus der regelm&auml;&szlig;igen
                Verj&auml;hrungsfrist laut &sect; 195 BGB.
            </p>
            <h2>6.2. Recht auf Berichtigung, L&ouml;schung, Einschr&auml;nkung</h2>
            <p>
                Sollten unrichtige personenbezogene Daten von Ihnen verarbeitet werden, steht Ihnen
                gem&auml;&szlig; Art. 16
                DS-GVO ein Recht auf Berichtigung zu. Liegen die erforderlichen Voraussetzungen vor, k&ouml;nnen Sie
                laut
                Art. 17, 18 DS-GVO die L&ouml;schung oder Einschr&auml;nkung der Verarbeitung Ihrer
                personenbezogenen Daten
                verlangen. Ihrem Wunsch auf Berichtigung, L&ouml;schung und Einschr&auml;nkung kommen wir gerne
                nach. Bitte
                richten Sie Ihren Wunsch schriftlich an unsere Datenschutzbeauftragten.<br/>
                <br/>
                Sollten Sie von Ihrem Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung Gebrauch machen,
                so speichern wir Ihr Ersuchen und die Antwort darauf, um unserer Rechenschaftspflicht
                nachzukommen.<br/>
                <br/>
                Auf welcher Rechtsgrundlage werden diese Daten verarbeitet und wie lange werden sie
                gespeichert?<br/>
                <br/>
                Im Rahmen der Rechte auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung, werden Ihre Daten auf
                der Grundlage einer rechtlichen Verpflichtung gem&auml;&szlig; Art. 6 Abs. 1 Satz 1 lit. c) DS-GVO
                verarbeitet. Die Speicherdauer eines Auskunftsersuchens und der daraus resultierenden Kommunikation
                betr&auml;gt [3] Jahre nach Abschluss des Auskunftsverfahrens. Diese Aufbewahrungsfrist ergibt sich
                aus der regelm&auml;&szlig;igen Verj&auml;hrungsfrist laut &sect; 195 BGB.<br/>
                <br/>
                Gibt es neben dem Verantwortlichen weitere Empf&auml;nger der Daten?<br/>
                <br/>
                Eine &Uuml;bermittlung Ihres Ersuchens an Dritte ist nicht vorgesehen. Sollte diese aus wichtigem
                Grund trotzdem erforderlich sein, werden Sie selbstverst&auml;ndlich hier&uuml;ber informiert.
            </p>
            <h2>6.3. Widerspruchsrecht</h2>
            <p>
                Nach Ma&szlig;gabe des Art. 21 DS-GVO haben Sie das Recht aus Gr&uuml;nden, die sich aus Ihrer
                besonderen
                Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die
                aufgrund eines berechtigten Interesses entsprechend Art. 6 Abs. 1 Satz 1 lit. f) DS-GVO erfolgt,
                Widerspruch einzulegen. Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht
                mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die
                Verarbeitung
                nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder die Verarbeitung dient
                der
                Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen. Bitte richten Sie Ihren
                Widerspruch
                schriftlich an unseren Datenschutzbeauftragten.<br/>
                <br/>
                Sollten Sie von Ihrem Widerspruchsrecht Gebrauch machen, so speichern wir Ihr Ersuchen und die
                Antwort darauf, um unserer Rechenschaftspflicht nachzukommen.<br/>
                <br/>
                Auf welcher Rechtsgrundlage werden diese Daten verarbeitet und wie lange werden sie
                gespeichert?<br/>
                <br/>
                Im Rahmen des Widerspruchsrechts, werden Ihre Daten auf der Grundlage einer rechtlichen
                Verpflichtung gem&auml;&szlig; Art. 6 Abs. 1 Satz 1 lit. c) DS-GVO verarbeitet. Die Speicherdauer
                eines Auskunftsersuchens und der daraus resultierenden Kommunikation betr&auml;gt [3] Jahre nach
                Abschluss
                des Auskunftsverfahrens. Diese Aufbewahrungsfrist ergibt sich aus der regelm&auml;&szlig;igen
                Verj&auml;hrungsfrist laut &sect; 195 BGB.<br/>
                <br/>
                Gibt es neben dem Verantwortlichen weitere Empf&auml;nger der Daten?<br/>
                <br/>
                Eine &Uuml;bermittlung Ihres Widerspruchs an Dritte ist nicht vorgesehen. Sollte diese aus wichtigem
                Grund trotzdem erforderlich sein, werden Sie selbstverst&auml;ndlich hier&uuml;ber informiert.
            </p>
            <h2>6.4. Kontakt zur Aufsichtsbeh&ouml;rde f&uuml;r den Datenschutz</h2>
            <p>
                Wenn Sie der Auffassung sind, dass eine von uns durchgef&uuml;hrte Verarbeitung personenbezogener
                Daten,
                gegen das Datenschutzrecht verst&ouml;&szlig;t, haben Sie das Recht, sich bei einer
                Datenschutzaufsichtsbeh&ouml;rde
                zu beschweren. Die f&uuml;r uns zust&auml;ndige Datenschutzaufsichtsbeh&ouml;rde, erreichen Sie
                unter folgenden
                Kontaktdaten:<br/>
                <br/>
                Bayerisches Landesamt f&uuml;r Datenschutzaufsicht<br/>
                Postfach 1349<br/>
                DE- 91504 Ansbach<br/>
                <br/>
                Telefon: +49 (0) 981 180093-0<br/>
                Telefax: +49 (0) 981 180093-800<br/>
                E-Mail: poststelle@lda.bayern.de
            </p>
            <h2>7. &Auml;nderung der Bestimmungen</h2>
            <p>
                Wir bitten um Beachtung der jeweils g&uuml;ltigen Version dieser Datenschutzerkl&auml;rung. Wir
                behalten uns
                das Recht zur &Auml;nderung der Datenschutzerkl&auml;rung aus technischen, organisatorischen oder
                rechtlichen
                Gr&uuml;nden vor.<br/>
                <br/>
                Stand der Datenschutzerkl&auml;rung: 10/2023
            </p>
        </div>

    </>
);

export default PrivacyPage;
