import * as React from "react";
import { Button } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

import "./FaqButton.css";

interface FaqButtonProps {
    onClickFaq: () => void;
}

export const FaqButton: React.FC<FaqButtonProps> = ({ onClickFaq }) => {
    return <Button className="btn-faq" onClick={() => onClickFaq()}>FAQ <QuestionCircleFilled /></Button>;
}
