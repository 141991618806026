import * as React from "react";
import { Flex, Spin } from "antd";
import "./ScanContainerSuccess.css";
import { LoadingOutlined } from "@ant-design/icons";

export const ScanWait = () => {
    return <Flex
        justify='center'
        align='center'
        className='success overlay'
    >
        <Flex
            justify='center'
            align='top'
            className='message'
        >
            <Flex
                justify='left'
                align='center'
                vertical={true}
            >
                <h1>Dein Scan wird verarbeitet</h1>

                <Flex
                    justify='center'
                    align='center'
                >
                    <Spin style={{ marginRight: '10px' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    <p style={{ display: 'contents' }}>Bitte warten ...</p>
                </Flex>
            </Flex>
        </Flex>
    </Flex>;
}
