import * as React from "react";
import { Button, Divider, Form, Input } from "antd";
import * as Api from "../../util/api";
import { PaymentData, PaymentErrorModalState, RefundType } from "../../types";
import { DepositPaidOff } from "../Scan/Message/DepositPaidOff";
import { isValidIBAN } from 'ibantools';
import type { InputRef } from "antd";
import { formatIbanField } from "../../util/functions";
import { globalConfig } from "../../configuration/config";

interface PaymentFormBanktransferProps {
    paymentData: PaymentData;
    showKdgToken: boolean;
    showMessage: (e: React.ReactElement | null) => void;
    onError: (e: PaymentErrorModalState) => void;
    setSwitchButtonActive: (b: boolean) => void;
}

export const PaymentFormBanktransfer: React.FC<PaymentFormBanktransferProps>= ({ paymentData, showKdgToken, showMessage, onError, setSwitchButtonActive }) => {
    const REG_EXP_ONLY_DIGITS = /^\d+$/;

    const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(true)
    const [iban, setIban] = React.useState("");
    const [cursor, setCursor] = React.useState<number | null>(null);
    const ref = React.useRef<InputRef>(null);
    const [isWaiting, setIsWaiting] = React.useState(false);

    React.useEffect(() => {
        if (!cursor) {
            return;
        }
        ref.current?.setSelectionRange(cursor, cursor);
    }, [ref, cursor, iban]);

    const updateCursor = (e: React.ChangeEvent<HTMLInputElement>) => {
        let cursorPosition = e.target.selectionStart;
        //calculate position after spaces
        if (cursorPosition !== null && cursorPosition % 5 === 0) {
            cursorPosition = cursorPosition + 1;
        }
        setCursor(cursorPosition);
    };

    const form = Form.useForm()[0];

    return <Form
        name="payment-form"
        form={form}
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
        }}
        onFieldsChange={() => {
            const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
            setIsSubmitDisabled(hasErrors)
        }}
        onValuesChange={ () => {
            // check if all input fields are valid
            let valueIban = form.getFieldValue('iban');
            setIban(valueIban);
        } }
    >
        <Form.Item
            name={["firstName"]}
            initialValue=""
            rules={[
                {
                    required: true,
                    message: 'Bitte geben sie ihren Vornamen an',
                },
                {
                    min: 2,
                    message: 'Der Vorname muss aus mindestens 2 Zeichen bestehen',
                },
                {
                    validator: async (_rule, value, _callback) => {
                        if (new RegExp(REG_EXP_ONLY_DIGITS).test(value)) {
                            return Promise.reject('Der Vorname darf nicht ausschließlich aus Ziffern bestehen');
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
            ]}
        >
            <Input
                className="payment-form-input input-banktransfer"
                placeholder="Vorname"
            />
        </Form.Item>
        <Form.Item
            name={["lastName"]}
            initialValue=""
            rules={[
                {
                    required: true,
                    message: 'Bitte geben Sie ihren Nachnamen an',
                },
                {
                    min: 2,
                    message: 'Der Nachname muss aus mindestens 2 Zeichen bestehen',
                },
                {
                    validator: (_rule, value, _callback) => {
                        if (new RegExp(REG_EXP_ONLY_DIGITS).test(value)) {
                            return Promise.reject('Der Nachname darf nicht ausschließlich aus Ziffern bestehen');
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
            ]}
        >
            <Input
                className="payment-form-input input-banktransfer"
                placeholder="Nachname"
            />
        </Form.Item>
        <Form.Item
            name={["iban"]}
            initialValue=""
            normalize={(value) => formatIbanField(value)}
            rules={[
                {
                    required: true,
                    message: 'IBAN ist ein Pflichtfeld',
                },
                {
                    validator: (_rule, value, _callback) => {
                        // strip off spaces from input for validation
                        const data = value.replaceAll(' ', '');

                        if (!isValidIBAN(data)) {
                            return Promise.reject('Bitte geben sie eine gültige IBAN ein');
                        }

                        if (globalConfig.get().briteActive) {
                            const supportedIbanCountries = ['DE'];
                            const ibanCountry = data.substring(0, 2).toUpperCase();
                            if (!supportedIbanCountries.includes(ibanCountry)) {
                                return Promise.reject('Aktuell werden nur deutsche Bankkonten unterstützt. Verwenden Sie bitte alternativ PayPal oder wenden Sie sich an den Kunden-Support.');
                            }
                        }

                        return Promise.resolve();
                    },
                },
            ]}
        >
            <Input
                ref={ref}
                onChange={updateCursor}
                className="payment-form-input input-banktransfer"
                placeholder="DE02 1203 0000 0000 2020 51"
            />
        </Form.Item>
        <Form.Item>
            <>
                <Button
                    style={{
                        color: (isSubmitDisabled ? (isWaiting ? "#131313" : "#b0b0b0")  : "#131313"),
                        background: (isSubmitDisabled ? "#f4f4f4" : "#ff81c5"),
                    }}
                    className="btn-get-disbursement"
                    shape="round"
                    size="middle"
                    htmlType="submit"
                    disabled={isSubmitDisabled}
                    onClick={() => {
                        setIsSubmitDisabled(true);
                        setIsWaiting(true);
                        setSwitchButtonActive(false);
                        Api.postReturnSubmit(
                            paymentData.porId,
                            paymentData.rpIds,
                            RefundType.bank_transfer,
                            form.getFieldValue('iban'),
                            form.getFieldValue('firstName'),
                            form.getFieldValue('lastName')
                        ).then((resp) => {
                            if (resp.status === 200) {
                                resp.json().then((body: any) => {
                                    if (body.status === 'succeeded') {
                                        showMessage(<DepositPaidOff showKdgToken={showKdgToken} transactionId={body.id}/>);
                                    } else {
                                        onError({ isOpen: true, infoText: "Die Auszahlung deines Pfands auf das von Dir angegebene Konto wurde vom Zahlungsanbieter noch nicht durchgeführt. Bitte setze Dich mit unserem Kundenservice in Verbindung um Dein Pfand zu erhalten.", showCustomerSupport: true })
                                        setIsSubmitDisabled(false);
                                    }
                                    setIsWaiting(false);
                                    setSwitchButtonActive(true);
                                });
                            }
                            if (resp.status >= 400 && resp.status < 600) {
                                resp.json()
                                    .then(() => {
                                        onError({ isOpen: true, infoText: "Hier ist etwas schief gelaufen, versuch es später noch einmal!", showCustomerSupport: false })
                                        setIsSubmitDisabled(false);
                                        setIsWaiting(false);
                                        setSwitchButtonActive(true);
                                    })
                                    .catch(() => {
                                        onError({ isOpen: true, infoText: "Hier ist etwas schief gelaufen, versuch es später noch einmal!", showCustomerSupport: false })
                                        setIsWaiting(false);
                                        setIsSubmitDisabled(false);
                                        setSwitchButtonActive(true);
                                    });
                            }
                        }).catch(() => {
                            onError({ isOpen: true, infoText: "Hier ist etwas schief gelaufen, versuch es später noch einmal!", showCustomerSupport: false })
                            setIsWaiting(false);
                            setIsSubmitDisabled(false);
                            setSwitchButtonActive(true);
                        });
                    }}
                >
                    {isWaiting ? "Bitte warten ..." : "Pfand auszahlen"}
                </Button>
                <Divider />
            </>
        </Form.Item>
    </Form>;
}
