import * as React from "react";
import { Button, Collapse, Flex } from "antd";
import { ArrowLeftOutlined, MinusCircleFilled, PlusCircleFilled, TabletOutlined } from "@ant-design/icons"
import "./FaqPage.css"

interface FaqProps {
    onClose: () => void;
}

const FaqPage: React.FC<FaqProps> = ({ onClose }) => {
    //TODO use <Link> of react router to link to "/map" routes. We currently use hrefs as this faq is just an overlay
    const items = [
        {
            key: '1',
            label: 'Was ist die Mehrwegverpackung von Multiloop?',
            children: <p>
                Multiloop ist eine umweltfreundliche Mehrwegverpackung. Unsere Verpackungen sind aus wiederverwertbarem Plastik hergestellt,
                ersetzten etwa 30 Pappkartons und sparen bis zu 86% CO2 und 93% Wasser. 
            </p>,
        },
        {
            key: '2',
            label: 'Wie kann ich meine Mehrwegverpackung wieder zurückgeben?',
            children: <p>
                Wie das genau geht, erfährst du <a href="https://multiloop.com/retoure" style={{ fontWeight: "bold", textDecoration: "none" }} rel="noreferrer" target="_blank">hier</a>.
            </p>,
        },
        {
            key: '3',
            label: 'Wo kann ich meine Multiloop Verpackung wieder zurückgeben?',
            children: <p>
                Deine Multiloop Verpackung kannst du an einer unserer Rückgabestellen zurückgeben.  
                <br/><a href="/locations" style={{ textUnderlineOffset: ".5em", textDecoration: "underline" }}>Zur Karte</a>
            </p>,
        },
        {
            key: '4',
            label: 'Wie erhalte ich mein Pfand zurück?',
            children: <p>
                Ganz einfach. Sobald du die Verpackung an einer unserer Rückgabestellen abgegeben hast, bekommst du dein 
                Pfand gutgeschrieben. Aktuell kannst du zwischen PayPal und einer Banküberweisung wählen. Du kannst dich
                während des Rückgabeprozesses für eine der beiden Alternativen entscheiden. Wenn du die Multiloop Verpackung 
                für die Retourensendung des teilnehmenden Händlers verwendest, wird dir dein Pfand zusammen mit dem Retourenbetrag erstattet.
            </p>,
        },
        {
            key: '5',
            label: 'Was mache ich, wenn meine Multiloop Verpackung beschädigt ist?',
            children: <p>
                Bitte gib die beschädigte Mehrwegverpackung trotzdem zurück. So bekommst du dein Pfand auch für eine 
                beschädigte Verpackung zurück und wir können sie recyceln und eine neue Multiloop Verpackung daraus machen. 
            </p>,
        },
        {
            key: '6',
            label: 'Erhalte ich einen Pfandbeleg?',
            children: <p>
                Aber klar! Nach der Pfanderstattung erhältst du einen Beleg von uns. Du kannst ihn direkt auf deinem 
                Smartphone speichern oder dir bequem per Email zusenden lassen.
            </p>,
        },
        {
            key: '7',
            label: 'Wie lange habe ich Zeit meine Multiloop Verpackung zurückzugeben?',
            children: <p>
                Bitte gib deine Verpackung so schnell wie möglich zurück, damit wir sie möglichst oft einsetzen können.
                So hilfst du mit, die Umwelt zu schonen.
            </p>,
        },
        {
            key: '8',
            label: 'Wie kann ich euch erreichen?',
            children: <p>
                Schreibe uns eine Mail an help@multiloop.com oder rufe uns an +49 (0)89-215415143. Wir sind von Montag 
                bis Freitag von 9:00 bis 19:00 Uhr für dich erreichbar.
                <br/><br/><a href="mailto:help@multiloop.com" style={{ textUnderlineOffset: ".5em", textDecoration: "underline" }}>Schreib uns</a>
            </p>,
        },
        {
            key: '9',
            label: 'Kann ich die Multiloop Verpackung behalten?',
            children: <p>
                Bitte gib deine Verpackung so schnell wie möglich zurück, damit wir sie möglichst oft einsetzen können. So 
                hilfst du mit, die Umwelt zu schonen. Die Verpackung ist Eigentum von Multiloop.
            </p>,
        },
    ];

    return <>
        <Flex
            className="faq"
            align="flex-start"
            vertical={true}
        >
            <Button
                type="link"
                style={{ color: "black", textDecoration: "none", fontSize: "20px", padding: "0 0 40px 16px", fontWeight: "bold" }}
                onClick={() => onClose()}
                icon={<ArrowLeftOutlined />}
                size="large"
            >Zurück</Button>
            <h1 className="faq-heading">Fragen & Antworten</h1>
            <Collapse
                expandIcon={({ isActive }) => {
                    return isActive ? <MinusCircleFilled style={{ fontSize: '200%', color: "hotpink" }}/> : <PlusCircleFilled style={{ fontSize: '200%' }}/>}
                }
                expandIconPosition={'end'}
                style={{ width: '100%' }}
                bordered={false}
                size="small"
                items={items}
            />
            <Flex justify="center" align="flex-start" vertical={true} className="phone">
                <h2>Du hast noch weitere Fragen?</h2>
                <p>Kontaktiere uns gerne direkt</p>
                <Button
                    className="btn-customer-support"
                    shape="round"
                    size="middle"
                    href="tel:+4989-215415143"
                >
                    +49 (0)89-215415143<TabletOutlined/>
                </Button>
                <a href="https://www.multiloop.com/impressum" className="links" rel="noreferrer" target="_blank">
                    Impressum
                </a>
                <a href="https://www.multiloop.com/datenschutzerklaerung" className="links" rel="noreferrer" target="_blank">
                    Datenschutz
                </a>
            </Flex>
        </Flex>
    </>
}

export default FaqPage;
