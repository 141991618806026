import * as React from "react";
import { Flex } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as Api from '../../util/api';
import FaqPage from "../FaqPage/FaqPage";
import { AssetType, DepositReceipt, Message, ScanMessage, Step } from "../../types";
import { ProgressHeader } from "../../components/ProgressHeader/ProgressHeader";
import { ScanContainerSuccess } from "../../components/Scan/Message/ScanContainerSuccess";
import { ScanFailure } from "../../components/Scan/Message/ScanFailure";
import "../../App.css";
import "./ScanPage.css";
import { extractIdentifierFromLegacyReturnUrl, extractIdentifierFromReturnUrl } from "../../util/functions";
import ScanditScanner from "../../components/Scan/Scanner/ScanditScanner";
import ViewFinder from "../../components/Scan/Scanner/ViewFinder";
import { ScanWait } from "../../components/Scan/Message/ScanWait";
import { useCallback } from "react";

const initDepositReceipt: DepositReceipt = {
    id: "",
    pointOfReturn: "",
    numberOfBoxes: 0,
    depositAmount: 0,
    currency: "",
    containedTax: 0,
    createdAt: null,
    scannedBoxIds: [],
};

const ScanPage: React.FC = () => {
    const { state } = useLocation()
    const [message, setMessage] = React.useState<Message>({
        // TODO follow-up: extract message vars to single variable of type 'component'
        messageType: ScanMessage.NONE,
        messageText: '',
        messageButtonText: '',
        navigateToPayment: null,
    });

    const isScannerActive = React.useRef(true);
    const stepRef = React.useRef(state ? Step.SCAN_BOX : Step.SCAN_CONTAINER);

    const depositRef = React.useRef<DepositReceipt>(state !== null ? state.depositReceipt : initDepositReceipt);
    const [faqPage, setFaqPage] = React.useState<React.ReactElement | null>(null);

    const navigate = useNavigate();
    const { porId } = useParams();

    React.useEffect(() => {
        if (!state) {
            return;
        }
        depositRef.current = state.depositReceipt;
    }, [state]);

    const switchToFaqPage = () => {
        const onClose = () => {
            setFaqPage(null);
            isScannerActive.current = true;
        };
        isScannerActive.current = false;
        setFaqPage(<FaqPage onClose={onClose} />);
    };

    const navigateToPayment = () => {
        navigate("/payment", { state: { depositReceipt: depositRef.current } });
    };

    const handleScannedContainer = useCallback(async (scannedStationQr: string) => {
        const porIdentifier = extractIdentifierFromReturnUrl(scannedStationQr, AssetType.PointOfReturn);
        if (null === porIdentifier) {

            setMessage({
                ...message,
                messageType: ScanMessage.SCAN_FAILURE,
                messageText: 'Dieser QR-Code gehört nicht zu einer bekannten Multiloop Rückgabestation',
                messageButtonText: 'QR Code Scan wiederholen',
            });
            return;
        }

        const pointOfReturn = await Api.getPointOfReturn(porIdentifier);
        if (null === pointOfReturn) {
            setMessage({
                ...message,
                messageType: ScanMessage.SCAN_FAILURE,
                messageText: 'Dieser QR-Code gehört nicht zu einer bekannten Multiloop Rückgabestation',
                messageButtonText: 'QR Code Scan wiederholen',

            });
            return;
        }

        // TODO all animations shall be made with Ant Motion
        // flash();
        setMessage({
            ...message,
            messageType: ScanMessage.SCAN_CONTAINER_SUCCESS,
        });

        depositRef.current = {
            ...depositRef.current,
            id: pointOfReturn.id,
            pointOfReturn: pointOfReturn.location.name,
            createdAt: new Date().getTime(),
        }
    }, [message]);

    const handleScannedBox = async (scannedBoxQr: string) => {
        let reusablePackaging = null;

        const graiIdentifier = extractIdentifierFromLegacyReturnUrl(scannedBoxQr);
        if (null !== graiIdentifier) {
            reusablePackaging = await Api.getReusablePackagingFromGrai(graiIdentifier);
        } else {
            const rpIdentifier = extractIdentifierFromReturnUrl(scannedBoxQr, AssetType.ReusablePackaging);
            if (null === rpIdentifier) {
                setMessage({
                    ...message,
                    messageType: ScanMessage.SCAN_FAILURE,
                    messageText: 'Dieser QR-Code gehört nicht zu einer bekannten Multiloop Box',
                    messageButtonText: 'Schließen',
                    navigateToPayment: depositRef.current.numberOfBoxes > 0 ? navigateToPayment : null,
                });

                return;
            }
            reusablePackaging = await Api.getReusablePackaging(rpIdentifier);
        }

        if (null === reusablePackaging) {
            setMessage({
                ...message,
                messageType: ScanMessage.SCAN_FAILURE,
                messageText: 'Dieser QR-Code gehört nicht zu einer bekannten Multiloop Box',
                messageButtonText: 'Schließen',
                navigateToPayment: depositRef.current.numberOfBoxes > 0 ? navigateToPayment : null,
            });

            return;
        }

        if (depositRef.current.scannedBoxIds.includes(reusablePackaging.id)) {
            setMessage({
                ...message,
                messageType: ScanMessage.SCAN_FAILURE,
                messageText: 'Dieser QR-Code wurde schon gescanned und diese Box bereits erfasst',
                messageButtonText: 'Schließen',
                navigateToPayment: navigateToPayment,
            });
            console.log(">> already scanned id", reusablePackaging.id);
            return;
        }

        const containedTax = '' !== reusablePackaging.deposit.amount ? Math.round(parseFloat(reusablePackaging.deposit.amount) / 1.19 * 0.19) : 0;

        depositRef.current = {
            ...depositRef.current,
            numberOfBoxes: depositRef.current.numberOfBoxes + 1,
            scannedBoxIds: [...depositRef.current.scannedBoxIds, reusablePackaging.id],
            depositAmount: parseFloat(reusablePackaging.deposit.amount) + depositRef.current.depositAmount,
            currency: reusablePackaging.deposit.currency,
            containedTax: depositRef.current.containedTax + containedTax,
        };
        navigateToPayment();
    };

    const handleScan = async (id: string) => {
        isScannerActive.current = false;
        setMessage({
            messageType: ScanMessage.SCAN_WAIT,
            messageText: 'Bitte warten ...',
            messageButtonText: '',
            navigateToPayment: null,
        });
        switch (stepRef.current) {
            case Step.SCAN_CONTAINER:
                setTimeout(async () => {
                    await handleScannedContainer(id);
                }, 500);
                return;
            case Step.SCAN_BOX:
                setTimeout(async () => {
                    await handleScannedBox(id);
                }, 500);
                return;
            default:
                throw new Error(`Invalid Step [${stepRef.current}]`);
        }
    }

    const scanMessageComponent = (() => {
        switch (message.messageType) {
            case ScanMessage.NONE:
                return null;
            case ScanMessage.SCAN_CONTAINER_SUCCESS:
                return <ScanContainerSuccess
                    timeoutCallback={() => {
                    // switch to next scan step (box scan)
                        isScannerActive.current = true;
                        const wf = {
                            ...message,
                            messageType: ScanMessage.NONE,
                        }
                        
                        stepRef.current = Step.SCAN_BOX;
                        setMessage(wf);
                    }}
                />;
            case ScanMessage.SCAN_FAILURE:
                return <ScanFailure
                    messageText={message.messageText}
                    buttonText={message.messageButtonText}
                    buttonCallback={() => {
                        setMessage({
                            ...message,
                            messageType: ScanMessage.NONE,
                        });
                        isScannerActive.current = true;
                    }}
                    navigateToPayment={message.navigateToPayment}
                />;
            case ScanMessage.SCAN_WAIT:
                return <ScanWait />;
            default:
                throw new Error(`Invalid ScanPage message component [${message.messageType}]`);
        }
    })();

    React.useEffect(() => {
        if (!porId) {
            return;
        }
        // TODO follow-up possibly replace with 'window.location.href' (React Router might not work for full URL resolution)
        handleScannedContainer("https://app.multiloop.com/por/" + porId);
    }, []);

    return <>
        <Flex
            vertical
            className={stepRef.current === Step.SCAN_CONTAINER ? 'container' : 'bg-scan-box'}
            align='center'
            style={{
                height: '100%',
            }}
        >
            <ProgressHeader step={stepRef.current} onClickFaq={switchToFaqPage} />

            <div className="scanner-container" style={{ width: "100%" }}>
                <ScanditScanner onScanCallback={handleScan} isScanning={isScannerActive.current}/>
                <ViewFinder 
                    helpText={
                        stepRef.current === Step.SCAN_CONTAINER
                            ? "<nobr>Scanne den QR-Code</nobr> <nobr>der Rückgabestation</nobr>"
                            : "<nobr>Scanne den QR-Code</nobr> <nobr>der Multiloop Box</nobr>"
                    } 
                    navigateToPayment={depositRef.current && depositRef.current.numberOfBoxes > 0 ? navigateToPayment : null}
                    flash={false} />
            </div>
        </Flex>
        {scanMessageComponent}
        {faqPage}
    </>
}

export default ScanPage;
