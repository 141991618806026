import { AssetType, LatLngLiteral, ReturnStation } from "../types";
import * as Api from './api';

/**
 * Opens a map application to show a specific location.
 * The map app depends on the user's device and browser.
 *
 * @param {LatLngLiteral} latLng The coordinates to open in the map app.
 */
export function openMapApp(latLng: LatLngLiteral): void {
    const { lat, lng } = latLng;

    const fallbackUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

    if (
        typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.userAgent
    ) {
    // Detecting iOS devices using the User-Agent string and excluding MSStream to prevent false positives
        const isIos =
      /iPad|iPhone|iPod/.test(window.navigator.userAgent) &&
      !(window.navigator as any).MSStream;

        // Detecting Android devices using the User-Agent string and excluding MSStream to prevent false positives
        const isAndroid =
      /android/i.test(window.navigator.userAgent) &&
      !(window.navigator as any).MSStream;

        if (isIos) {
            // Constructing URLs for iOS devices; separate URLs for Apple Maps and Google Maps
            const appleMapsUrl = `https://maps.apple.com/?daddr=${lat},${lng}&dirflg=d`;
            const googleMapsUrl = `https://maps.google.com/maps?daddr=${lat},${lng}&amp;ll=`;

            if (
                window &&
        window.navigator &&
        window.navigator.userAgent &&
        !window.navigator.userAgent.includes("CriOS")
            ) {
                window.open(appleMapsUrl);
            } else {
                window.open(googleMapsUrl);
            }
        } else if (isAndroid) {
            // Opening geo URL scheme for Android devices to invoke native maps app
            window.open(`geo:${lat},${lng}?q=${lat},${lng}`);
        } else {
            // Falling back to fallback URL if the device is neither iOS nor Android
            window.open(fallbackUrl);
        }
    }
}

/**
 * Fetches the name of a POR from the API using its identifier.
 *
 * @param {string} identifier The Identifier of the POR to get the name of.
 * @returns {Promise<string>} A promise resolving to the name of the POR, or an empty string if the station could not be found or an error occurred.
 */
export async function getPointOfReturnName(identifier: string): Promise<string> {
    const por = await Api.getPointOfReturn(identifier); //TODO handle timeout

    // If no station name is received, returning an empty string;
    return por ? por.location.name : '';
}

export function extractIdentifierFromReturnUrl(data: string, assetType: AssetType) {
    if (!data.startsWith('http')) {
        return null;
    }

    const regex = new RegExp(`app\\.multiloop\\.com\\/(?:return\\/)?${assetType}\\/([0-9a-zA-Z]{26})`);
    const match = data.match(regex);
    if (match) {

        return match[1]
    }

    return null
}

export function extractIdentifierFromLegacyReturnUrl(data: string) {
    if (!data.startsWith('http')) {
        return null;
    }

    const regex1 = new RegExp(`app\\.multiloop\\.com\\/start\\/8003/0([0-9]*)`);
    const match1 = data.match(regex1);
    if (match1) {

        return match1[1]
    }

    const regex2 = new RegExp(`app\\.multiloop\\.com\\/start\\/80030([0-9]*)`);
    const match2 = data.match(regex2);
    if (match2) {

        return match2[1]
    }

    return null
}

export function formatMoney(amount: number): string {
    if (!amount) {
        amount = 0;
    }

    const currencyFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    return currencyFormatter.format(amount / 100);
}

export function formatIbanField(value: string): string {
    const CHAR_GROUP_SIZE = 4;

    value = value.trim();
    value = value.toUpperCase();
    value = value.replaceAll(' ', '');

    let groupedValue = '';
    for (let i = 0; i < value.length; i++) {
        if (i > 0 && i % CHAR_GROUP_SIZE === 0) {
            groupedValue = groupedValue.concat(' ');
        }
        groupedValue = groupedValue.concat(value.charAt(i));
    }

    return groupedValue;
}

/**
 * Fetches return stations from the API and populates the returnStations state with structured data
 */
export async function requestReturnStations(): Promise<ReturnStation[]> {
    const DEFAULT_DESC = "Bitte erkundige dich vorher über aktuelle Öffnungszeiten.";

    const pointsOfReturn = await Api.listPointOfReturn();
    return pointsOfReturn
        .filter((por: any) => { // TODO - specify types
            // use only stations with coordinates
            return (
                por.id !== null &&
                por.latitude !== null &&
                por.longitude !== null
            );
        })
        .map((por: any) => { // TODO - specify types
            return {
                id: por.id,
                name: por.name || "Rückgabestation",
                addressLine1: por.addressLine1 || "",
                addressLine2: por.addressLine2 || "",
                addressLine3: por.addressLine3 || "",
                postalCode: por.postalCode || "",
                city: por.city || "",
                description: por.locationDesc || DEFAULT_DESC,
                location: {
                    lat: por.latitude,
                    lng: por.longitude,
                },
            };
        });
}
