export interface DynamicConfig {
    apiUrl: string;
    environment: "DEV" | "TEST" | "PROD";
    mapsApiKey: string;
    scanditLicenseKey: string;
    paypalActive: boolean;
    briteActive: boolean;
    appInsightsConnectionString: string;
}

class GlobalConfig {
    config: DynamicConfig|null = null

    public get(): DynamicConfig {
        if (null === this.config) {
            throw new Error("Global config has not been defined yet.");
        }

        return this.config;
    }

    public set(value: DynamicConfig): void {
        if (null !== this.config) {
            throw new Error("Global config has already been defined");
        }
        this.config = value;
    }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "/config.json";
