import * as React from "react";
import {
    LoadingOutlined,
} from '@ant-design/icons';
import { Flex } from 'antd';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import pinIconBlue from "../../../assets/map-icons/pinBlue.svg";
import pinIconCluster from "../../../assets/map-icons/pinBlueNoLogo.svg";

import {
    GoogleMap,
    CircleF,
    MarkerF, // ! State reloads not working
    DirectionsRenderer,
} from "@react-google-maps/api";

import { ReturnStation } from "../../../types";
import "./Map.css";

type LatLngLiteral = google.maps.LatLngLiteral;
type DirectionsResult = google.maps.DirectionsResult;
type MapOptions = google.maps.MapOptions;
type GoogleMapType = google.maps.Map;

export enum MapType {
    LandingPage,
    LocationPage,
}

const MAP_ID = "c4f9d0b4ca480b2b";

/**
 * Interface representing the properties accepted by the Map component.
 */
interface IMapProps {
    /**  Array of return stations available for selection on the map. */
    returnStations: ReturnStation[];
    /** The return station currently selected. If null, no station is selected.*/
    selectedReturnStation: ReturnStation | null;
    /** The current travel mode selected by the user, determining the type of directions rendered on the map. */
    travelMode: google.maps.TravelMode | null;
    /** The directions result object containing information about the route to be rendered on the map. */
    directions: DirectionsResult | null;
    /** Handler called when a return station is selected, passing the selected station or null if the selection is cleared. */
    onReturnStationSelection: (station: ReturnStation | null) => unknown;
    /** Handler called when new directions are retrieved, passing the directions result or null if directions cannot be retrieved. */
    onNewDirections: (directions: DirectionsResult | null) => void;
    /** Handler called to change the travel mode, passing the new travel mode to be used in fetching directions. */
    onChangeTravelMode: (mode: google.maps.TravelMode) => void;

    enableDirections: boolean

    mapType: MapType;
}

/**
 * The `Map` component renders a Google Map with various layers including a traffic layer, a bicycling layer, and a directions renderer.
 * It features markers representing return stations and the user's current location and facilitates fetching and rendering directions based on user interaction.
 *
 * @param props - The properties configuring the map.
 * @returns A React Element representing the customized Google Map along with associated controls such as the GPS button.
 */
const GMap: React.FC<IMapProps> = ({
    returnStations,
    selectedReturnStation,
    travelMode,
    directions,
    onReturnStationSelection,
    onNewDirections,
    onChangeTravelMode,
    enableDirections,
    mapType,
}: IMapProps) => {
    const mapStyles = {
        width: "100%",
        display: "flex",
        // flexDirection: 'column',
        justifyContent: "center",
        zIndex: 0,
        // paddingTop: "52px"
    };

    const zoom = 14;
    const mapRef = React.useRef<GoogleMapType>();
    // Initializing the Google Map component with predefined settings such as
    // disabling the default UI and clickable icons to provide a cleaner map interface
    const options = React.useMemo<MapOptions>(
        () => ({
            mapId: MAP_ID,
            disableDefaultUI: true,
            clickableIcons: false,
            zoomControl: true,
        }),
        []
    );

    // Fallback: center of germany
    const [center, setCenter] = React.useState<LatLngLiteral>(() => {
        return mapType === MapType.LandingPage ? { lat: 50.9916175, lng: 10.2424991 } : { lat: 52.5169182, lng: 13.4035663 };
    });
    const [isFirstLoad, setIsFirstLoad] = React.useState(true);

    const [currentLocation, setCurrentLocation] = React.useState<LatLngLiteral>(center);
    const pinImagesMapping = React.useRef<Map<string, HTMLImageElement>>(new Map());
    const highlightedStationId = React.useRef<string|null>(null);

    const onLoad = React.useCallback((map: GoogleMapType) => {
        // calculate boundaries of germany for landing page
        if (mapType === MapType.LandingPage) {
            const ne = { lat: 53.737742391466504, lng: 14.547385996734575 };
            const se = { lat: 47.78002346039291, lng: 13.159018369154493 };
            const sw = { lat: 47.80216647878276, lng: 7.4461290460720075 };
            const nw = { lat: 54.45302804954254, lng: 6.621434827082853 };
            const boundsList = [ne, se, sw, nw];

            var bounds = new google.maps.LatLngBounds();
            boundsList.forEach((b) => bounds.extend(b));
            map.fitBounds(bounds);
        }
        mapRef.current = map;
    }, []);

    // Triggered on component mount and sets the initial map center
    // to the current user location retrieved through the browser's geolocation API.
    React.useEffect(() => {
        handleGetCurrentLocation(); // TODO - update dependency array
    }, []);

    React.useEffect(() => {
        if (!mapRef.current) {
            return;
        }

        if (highlightedStationId.current) {
            const currentHighlighted = pinImagesMapping.current.get(highlightedStationId.current);
            if (currentHighlighted) {
                currentHighlighted.width = 30;
                currentHighlighted.height = 30;
            }
        }

        if (!selectedReturnStation) {
            highlightedStationId.current = null;

            return;
        }

        highlightedStationId.current = selectedReturnStation?.id
        const newHighlighted = pinImagesMapping.current.get(highlightedStationId.current);
        if (newHighlighted) {
            newHighlighted.width = 42;
            newHighlighted.height = 42;
        }

        fetchDirections(selectedReturnStation, travelMode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [travelMode, selectedReturnStation]);

    /**
   * Sets the selected return station and fetches the direction to it based on the chosen travel mode.
   * Also triggers a re-render with new directions displayed on the map.
   *
   * @param {ReturnStation} station The newly selected return station
   */
    const handleReturnStationSelection = (station: ReturnStation) => {
        onReturnStationSelection(station);

        fetchDirections(station, travelMode);

        onNewDirections(directions);

        // setCenter(() => station.location);
    };

    /**
   * Prompts and retrieves the users current location if available and recenters the surrounding map.
   *
   * An alert message is shown if an error occurs.
   */
    const handleGetCurrentLocation = () => {
        // turn off asking for geolocation on LandingPage
        if (mapType === MapType.LandingPage) {
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                setCenter({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            },
            (error) => {
                console.error(error);
                if (error.code === 1) {
                    alert("Bitte erlaube den Zugriff auf deinen Standort.");
                }
            }
        );
    };

    /**
   * Fetches the route direction from the current location to a selected return station.
   *
   * It employs Google Maps Direction Service, and based on the travel mode (default BICYCLING),
   * it draws the route on the map.
   * If no route is found, it falls back to DRIVING mode as a secondary option.
   *
   * @param {ReturnStation} station The selected return station as the destination point.
   * @param {google.maps.TravelMode} travelMode The desired mode of transportation.
   */
    const fetchDirections = (
        station: ReturnStation,
        travelMode: google.maps.TravelMode | null
    ) => {
        if (!currentLocation) {
            console.error("No current location");
            alert("No current location");
            return;
        }
        if (!enableDirections) {
            return;
        }

        const service = new google.maps.DirectionsService();
        service.route(
            {
                origin: center,
                destination: station.location,
                travelMode: travelMode ?? google.maps.TravelMode.BICYCLING,
            },
            (result, status) => {
                if (status === "OK" && result) {
                    onNewDirections(result);
                } else {
                    // alert("Directions request failed due to " + status);
                    if (status === "ZERO_RESULTS") {
                        console.log("No route found, trying driving");
                        if (travelMode !== google.maps.TravelMode.DRIVING)
                            onChangeTravelMode(google.maps.TravelMode.DRIVING);
                    } else {
                        console.log("Directions request failed due to " + status);
                    }
                }
            }
        );
    };

    // Rendered while the Map API is loading
    if (!window.google) {
        return (
            <Flex justify={'center'} align={'center'} style={{ height: '100vh' }}>
                <LoadingOutlined spin style={{ fontSize: '32px' }} />
            </Flex>
        );
    }

    const opacity = mapType === MapType.LandingPage ? 0 : 1;

    const getIcon = () => {
        const icon = document.createElement('img');
        icon.src = pinIconBlue;
        icon.width = 30;
        icon.height = 30;
        return icon;
    };

    async function initMap() {
        if (!isFirstLoad) {
            return;
        }
        if (returnStations.length <= 0) {
            return;
        }
        const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;

        const markers = returnStations.map((station: ReturnStation) => {
            pinImagesMapping.current.set(station.id, getIcon());

            const marker = new AdvancedMarkerElement({
                position: station.location,
                content: pinImagesMapping.current.get(station.id),
                gmpClickable: true,
            });

            marker.addListener("click", () => handleReturnStationSelection(station));
            return marker;
        });

        const renderer = {
            //@ts-ignore
            render({ count, position }) {
                const icon = document.createElement('div');
                icon.className = 'map-icon';
                icon.textContent = String(count);
                icon.style.backgroundImage = `url(${pinIconCluster})`;

                return new AdvancedMarkerElement({
                    position,
                    content: icon,         
                    gmpClickable: true,
                });
            },
        };

        new MarkerClusterer({ markers: markers, map: mapRef.current, renderer: renderer });
        setIsFirstLoad(false);
    }

    initMap();
    // The Map component is wrapped with a <div> to facilitate relative positioning of the child elements such as the GPS button.
    // The Google Map component encompasses various layers including current location marker, return station markers, and direction renderer to draw routes.
    return (
        <div style={{ position: "relative" }}>
            <GoogleMap
                zoom={zoom}
                center={center}
                options={options}
                mapContainerStyle={{
                    ...mapStyles,
                    height: '100vh',
                }}
                onLoad={onLoad}
            >
                <CircleF
                    center={currentLocation}
                    radius={20}
                    options={{
                        fillColor: '#0078d4',
                        fillOpacity: 0.2,
                        strokeColor: '#0078d4',
                        strokeOpacity: 0.5,
                        strokeWeight: 1,
                    }}
                />
                <MarkerF
                    position={currentLocation}
                    icon={{
                        fillColor: '#323130',
                        fillOpacity: opacity,
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 8,
                        strokeColor: '#ffffff',
                        strokeWeight: 2,
                    }}
                />
                <DirectionsRenderer
                    directions={directions !== null ? directions : undefined}
                    options={{
                        suppressMarkers: true,
                        polylineOptions: {
                            zIndex: 50,
                            strokeColor: '#0078d4',
                            strokeWeight: 5,
                        },
                    }}
                />
            </GoogleMap>
        </div>
    );
};

export default GMap;
