import React from 'react';
import { Outlet } from "react-router-dom";
import { App as AntApp, Button, ConfigProvider, Flex, Layout, theme } from "antd";
import { AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { globalConfig } from './configuration/config';
import { createBrowserHistory } from "history";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const { Content } = Layout;

const Root: React.FC = () => {
    const rootComponent = <ConfigProvider
        theme={{
            algorithm: theme.defaultAlgorithm,
            components: {
                Layout: {
                    headerBg: "rgb(255, 255, 255)",
                    bodyBg: 'rgb(255, 255, 255)',
                    footerBg: "rgb(46,17,66)",

                    footerPadding: '4px',
                },
                Button: {
                    defaultActiveColor: 'rgba(0, 0, 0, 0.88)',
                    defaultHoverColor: 'rgba(0, 0, 0, 0.88)',
                },
            },
        }}
    >
        <AntApp>
            <Layout>
                <Content>
                    <Outlet/>
                </Content>
            </Layout>
        </AntApp>
    </ConfigProvider>;

    if (globalConfig.get().environment === "DEV") {
        return rootComponent;
    }

    const browserHistory = createBrowserHistory({});
    let reactPlugin = new ReactPlugin();
    const connectionString = globalConfig.get().appInsightsConnectionString;
    const appInsights = new ApplicationInsights({
        config: {
            connectionString: `${connectionString}`,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView({
        name: "Device Tracking", properties: {
            "screen_size": window.screen.width + "x" + window.screen.height,
        },
    });

    return <AppInsightsErrorBoundary
        onError={() => {
            return <Flex align="center" vertical>
                <h1>Entschuldigung - hier ist etwas schief gelaufen oder die Seite existiert nicht mehr.</h1>
                <Button href="https://multiloop.com/retoure">Zurück</Button>
            </Flex>
        } 
        }
        appInsights={reactPlugin}>
        {rootComponent}
    </AppInsightsErrorBoundary>
};

export default Root;
