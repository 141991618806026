import React, { ReactElement } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { globalConfig, globalConfigUrl } from "./configuration/config";

fetch(globalConfigUrl)
    .then((response) => {
        if (response.ok) {
            return response.json();
        }

        throw new Error("Failed fetching global config", { cause: response });
    })
    .then((config) => {
        globalConfig.set(config);

        return (
            <App />
        )
    })
    .catch(e => {
        return <p style={{ color: "red", textAlign: "center" }}>Error while fetching global config</p>;
    })
    .then((reactElement: ReactElement) => {
        ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
            reactElement
        );
    })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
