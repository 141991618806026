import * as React from "react";

import { useLoadScript } from "@react-google-maps/api";
import GMap, { MapType }from "../../components/Locations/Map/Map";
import OpenInNavAppButton from "../../components/Locations/OpenInNavAppButton/OpenInNavAppButton";
import { ReturnStation } from "../../types";
import { globalConfig } from "../../configuration/config";
import { Button, Drawer, Space, Typography } from "antd";
import { ArrowLeftOutlined, QuestionCircleFilled } from "@ant-design/icons";
import FaqPage from "../FaqPage/FaqPage";

import './LocationPage.css';
import { requestReturnStations } from "../../util/functions";

const { Text } = Typography;

const mapLibraries: (
    | "places"
    | "drawing"
    | "geometry"
    | "visualization"
    )[] = ["places"];

/**
 * Interface representing the props structure.
 * @remarks
 * Currently no props expected. Might contain defaultDescriptions and similar in the future.
 */
interface ILocationsPageProps {}

/**
 * A page component displaying a map with various return stations represented on it. It supports selecting return stations to view details and receive directions based on different travel modes. It also supports opening the location in a navigation app.
 *
 */
const LocationsPage: React.FC<ILocationsPageProps> = () => {
    // Load the necessary Google Maps libraries.
    const isMapLoaded = useLoadScript({
        googleMapsApiKey: globalConfig.get().mapsApiKey,
        libraries: mapLibraries,
    });

    const [selectedReturnStation, setSelectedReturnStation] = React.useState<ReturnStation | null>(null);
    const [returnStations, setReturnStations] = React.useState<ReturnStation[]>([]);

    const [isFirstLoad, setIsFirstLoad] = React.useState(true);
    const [faqPage, setFaqPage] = React.useState<React.ReactElement | null>(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    // Populate return stations when map is loaded
    React.useEffect(() => {
        if (isFirstLoad && isMapLoaded) {
            setIsFirstLoad(false);
            setTimeout(() => {
                requestReturnStations().then(
                    (returnStations:ReturnStation []) => { 
                        setReturnStations(returnStations);
                    }
                );
            }, 0);
        }
    }, [isMapLoaded, isFirstLoad]);

    // Show a loading message while the Google Maps libraries are being loaded
    if (!isMapLoaded) {
        return <div>Loading...</div>;
    }

    const navigateBack = () => {
        window.location.href = "https://www.multiloop.com/retoure";
    };

    const switchToFaqPage = () => {
        const onClose = () => {
            setFaqPage(null);
        };
        setFaqPage(<FaqPage onClose={onClose} />);
    };

    const onDrawerClose = () => {
        setDrawerOpen(false);
        setSelectedReturnStation(null);
    };

    return (
        <>
            <Button 
                className="map-button"
                style={{
                    position: "absolute",
                    left: "30px",
                    marginTop: "60px",
                }}
                onClick={navigateBack} 
                icon={<ArrowLeftOutlined />}
            >
                Zurück
            </Button>
            <Button 
                className="map-button"
                style={{
                    position: "absolute",
                    right: "30px",
                    marginTop: "60px",
                }} 
                onClick={switchToFaqPage}
            >
                FAQ <QuestionCircleFilled />
            </Button>
            <GMap
                returnStations={returnStations}
                selectedReturnStation={selectedReturnStation}
                travelMode={null}
                directions={null}
                enableDirections={false}
                onReturnStationSelection={(station: ReturnStation | null) => {
                    setSelectedReturnStation(station);
                    setDrawerOpen(true);
                }}
                onNewDirections={() => { }}
                onChangeTravelMode={() => { }}
                mapType={MapType.LocationPage}
            />
            <Drawer
                title={selectedReturnStation && selectedReturnStation.name}
                placement='bottom'
                height='auto'
                onClose={onDrawerClose}
                open={drawerOpen && (selectedReturnStation !== null)}
                className={'map-drawer'}
                style={{
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                }}
                styles={{
                    header: {
                        borderBottom: '0',
                        padding: '20px 20px 6px',
                    },
                    body: {
                        padding: '6px 20px 48px',
                    },
                }}
            >
                {selectedReturnStation && (
                    <Space direction="vertical" size={24} style={{ width: '100%' }}>
                        {selectedReturnStation.addressLine1 && (
                            <Text>
                                {selectedReturnStation.addressLine1}<br/>
                                {selectedReturnStation.postalCode} {selectedReturnStation.city}
                            </Text>
                        )}
                        {selectedReturnStation.description && (
                            <Text>
                                <Text strong>Öffnungszeiten:</Text><br />
                                <Text style={{ whiteSpace: 'pre-wrap' }}>
                                    {selectedReturnStation.description}
                                </Text>
                            </Text>
                        )}
                        <OpenInNavAppButton location={selectedReturnStation.location} />
                    </Space>
                )}
            </Drawer>
            {faqPage}
        </>
    );
};

export default LocationsPage;
