import * as React from "react"; import { Button, Flex } from "antd";

import ImageScannerCrosshair from "../../../assets/pages/scan/scanner/scanner_crosshair.png";
import ImageScannerQrCode from "../../../assets/pages/scan/scanner/qr_code.png";
import { ArrowRightOutlined } from "@ant-design/icons";

import "./ViewFinder.css";

/**
 * Interface representing the properties expected by the ViewFinder component.
 */
interface IViewFinderProps {
    /** A boolean to indicate whether the flash effect should be applied. */
    flash?: boolean;
    /** Optional text to provide help or guidance to the users. */
    helpText?: string;
    navigateToPayment: null | (() => void);
}

/**
 * Component to display a viewfinder UI during a scanning session. 
 * 
 * It includes a scanner crosshair and a QR icon at the center of the crosshair. 
 * Additionally, it can display a help text at the bottom of the scanning viewport 
 * and can apply a flash effect on successful scan.
 *
 * @param props The properties adhering to the IViewFinderProps interface.
 */
const ViewFinder: React.FC<IViewFinderProps> = ({
    flash,
    helpText,
    navigateToPayment,
}: IViewFinderProps) => {
    return (
        <div>
            <Flex
                // Apply a flash effect if the 'flash' prop is true
                className={flash ? "flash-effect" : ""}
                justify="center"
                align="center"
                style={{
                    position: "absolute",
                    height: "100%",
                    width: "100vw",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                }}
            >
                {/* SVG representing the scanner crosshair */}
                <img
                    src={ImageScannerCrosshair}
                    className="opacity-change"
                    style={{
                        width: "40%",
                        height: "auto",
                        maxWidth: "40%",
                    }}
                    alt=""
                />

                {/* Icon representing a QR code, displayed with reduced opacity */}
                <img
                    src={ImageScannerQrCode}
                    className=""
                    style={{
                        margin: "auto",
                        opacity: "0.2",
                        width: "30%",
                        height: "auto",
                        position: "absolute",
                    }}
                    alt=""
                />
            </Flex>

            {/* Display help text at the bottom of the viewfinder, if provided */}
            {helpText && (
                <Flex
                    align="center"
                >
                    <Flex align="center" style={{ width: '100%' }}>
                        <span
                            className="help-text"
                            dangerouslySetInnerHTML={ { __html: helpText } }
                        />
                    </Flex>
                    {
                        navigateToPayment &&
                            <Flex align="center">
                                <Button
                                    className="btn-payment" 
                                    onClick={() => navigateToPayment?.()}
                                >Zurück<ArrowRightOutlined /></Button>
                            </Flex>
                    }
                </Flex>
            )}
        </div>
    );
};

export default ViewFinder;
