import './ImprintPage.css';
import * as React from "react";
import { Link } from "react-router-dom";

const ImprintPage: React.FC = () => (
    <>

        <div className="imprint">
            <header>
                <h1>Impressum</h1>
            </header>
            <p>
                <strong>Multiloop GmbH</strong><br/>
                <strong>Zugspitzstr. 15</strong><br/>
                <strong>DE-82046 Pullach</strong><br/>
                <br/>
                <strong>Telefon:</strong> +49 (0)89 215 4151 40<br/>
                <strong>Telefax:</strong> +49 (0)89 215 4151 49<br/>
                <strong>E-Mail:</strong> info(a)multiloop.com<br/>
                <br/>
                Sitz:<br/>
                Pullach, Amtsgericht München HRB 285164<br/>
                <br/>
                USt-ID-Nr.: DE363123276<br/>
                <br/>
                Gesetzlich vertreten durch die Geschäftsführer:<br/>
                Peter Pilenghi, Leif Erichson
            </p>
            <h2>Verwendung der Websites</h2>
            <p>
                Die Informationen auf diesen Websites sind unverbindlich und werden ausschließlich zu
                Informationszwecken zur Verfügung gestellt. Sie haben lediglich Informationscharakter und sind
                keine Offerten/Angebote im Sinne der anwendbaren gesetzlichen Bestimmung
                en. Auf der Grundlage der auf der Website enthaltenen Informationen kann kein Vertragsverhältnis
                hinsichtlich der vorgestellten Produkte und Dienstleistungen entstehen.<br/>
                <br/>
                Die Informationen auf diesen Websites sowie die in diesen Informationen beschriebenen Produkte und
                Dienstleistungen können ohne vorherige Ankündigung von der Multiloop GmbH jederzeit geändert oder
                aktualisiert werden. Soweit nicht ausdrücklich anderweitig erklärt, enthalten die Websites der
                Multiloop GmbH keinerlei Garantien oder Beschaffenheitsangaben, für welche die Multiloop GmbH
                haftet, sei es ausdrücklich oder stillschweigend auch nicht hinsichtlich der Aktualität,
                Korrektheit, Vollständigkeit und Qualität der Informationen.<br/>
                <br/>
            </p>
            <h2>Datenerhebung und Datenverarbeitung</h2>
            <p>
                <Link to='/privacy'>siehe Datenschutz</Link>
            </p>
            <h2>Auskunftsrecht</h2>
            <p>
                Wenn Sie Fragen hinsichtlich der Verarbeitung Ihrer persönlichen Daten haben wenden Sie sich bitte
                an: Leif Erichson, info(@)multiloop.com
            </p>
            <h2>E-Mail per Internet</h2>
            <p>
                Wenn Sie innerhalb unserer Websites Seiten und Dateien abrufen und dabei aufgefordert werden, Daten
                über sich einzugeben, so weisen wir darauf hin, dass diese Datenübertragung über das Internet
                ungesichert erfolgt und die Daten somit von Unbefugten zur Kenntnis genommen oder auch verfälscht
                werden können.
            </p>
            <h2>Haftung und Links zu anderen Websites - Disclaimer</h2>
            <p>
                Wir sind für die Inhalte unserer Websites nach den Maßgaben der allgemeinen Gesetze, insbesondere
                nach § 7 Abs. 1 des Telemediengesetzes, verantwortlich. Alle Inhalte werden mit der gebotenen
                Sorgfalt und nach bestem Wissen erstellt. Soweit wir auf unseren Websites mittels Hyperlink auf
                Internetseiten Dritter verweisen, können wir keine Gewähr für die fortwährende Aktualität,
                Richtigkeit und Vollständigkeit der verlinkten Inhalte übernehmen, da diese Inhalte außerhalb
                unseres Verantwortungsbereichs liegen und wir auf die zukünftige Gestaltung keinen Einfluss haben.
                Sollten aus Ihrer Sicht Inhalte gegen geltendes Recht verstoßen oder unangemessen sein, teilen Sie
                uns dies bitte mit.<br/>
                <br/>
                Die rechtlichen Hinweise auf dieser Seite sowie alle Fragen und Streitigkeiten im Zusammenhang mit
                der Gestaltung dieser Internetseite unterliegen dem Recht der Bundesrepublik Deutschland.
            </p>
            <h2>Marken- und Urheberrechte, Datenmaterial</h2>
            <p>
                Die Marken und Logos, die auf diesen Websites dargestellt sind, sind Eigentum der Multiloop GmbH.
                Einige Websites enthalten auch Logos, die dem Urheberrecht derjenigen unterliegen, die diese zur
                Verfügung gestellt haben. Keine der Informationen auf diesen Websites ist als eine Gewährung von
                Lizenzen oder Marken auszulegen. Hierfür ist eine ausdrückliche schriftliche Einwilligung der
                Multiloop GmbH notwendig. Die unbefugte Verwendung dieser Marken ist strengstens verboten. Die
                Multiloop GmbH wird ihre geistigen Eigentumsrechte weltweit im Wege des jeweils einschlägigen
                Rechtsschutzes durchsetzen.<br/>
                <br/>
                Alle Texte, Bilder, Graphiken, Ton-, Video- und Animationsdateien unterliegen dem Urheberrecht und
                anderen Gesetzen zum Schutz geistigen Eigentums. Sie dürfen weder zur Weitergabe kopiert, noch
                verändert und auf anderen Websites verwendet werden. Einige Websites enthalten auch Bilder und
                Logos, die dem Urheberrecht derjenigen unterliegen, die diese zur Verfügung gestellt haben.<br/>
                <br/>
                Auch das Datenmaterial ist urheberrechtlich geschützt. Automatisierte Abfragen bzw. eine
                Vervielfältigung desselben (insbesondere durch automatisiertes Auslesen, sog. „Scraping“) ist
                untersagt. Insbesondere untersagt ist auch die sonstige Nutzung des Datenmaterials zum Zwecke der
                kommerziellen Weiterverwendung.
            </p>
        </div>

    </>
);

export default ImprintPage;
