import '../../App.css';
import * as React from "react";

const ContactPage: React.FC = () => (
    <>

        <div>
            <header>
                <h1>Contact Page will live here</h1>
            </header>
        </div>

    </>
);

export default ContactPage;
