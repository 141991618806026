import * as React from "react";
import * as EmailValidator from 'email-validator';
import { Button, Divider, Form, Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import paypalIcon from "../../assets/pages/payment/icon_paypal.svg";
import * as Api from "../../util/api";
import { PaymentData, PaymentErrorModalState, RefundType } from "../../types";
import { DepositPaidOff } from "../Scan/Message/DepositPaidOff";

interface PaypalFormProps {
    paymentData: PaymentData;
    showKdgToken: boolean;
    showMessage: (e: React.ReactElement | null) => void;
    onError: (e: PaymentErrorModalState) => void;
    setSwitchButtonActive: (b: boolean) => void;
}

export const PaymentFormPaypal: React.FC<PaypalFormProps> = ({ paymentData, showKdgToken, showMessage, onError, setSwitchButtonActive }) => {
    const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(true);
    const [paypalEmail, setPaypalEmail] = React.useState('');
    const [waiting, setWaiting] = React.useState(false);

    const submitData = () => {
        setIsSubmitDisabled(true);
        setWaiting(true);
        setSwitchButtonActive(false);
        setTimeout(() => {
            Api.postReturnSubmit(
                paymentData.porId,
                paymentData.rpIds,
                RefundType.paypal,
                paypalEmail
            ).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((body: any) => {
                        if (body.status === 'succeeded') {
                            showMessage(<DepositPaidOff showKdgToken={showKdgToken} transactionId={body.id}/>);
                        } else {
                            onError({ isOpen: true, infoText: "Die Auszahlung deines Pfands auf das von Dir angegebene Konto wurde vom Zahlungsanbieter noch nicht durchgeführt. Bitte setze Dich mit unserem Kundenservice in Verbindung um Dein Pfand zu erhalten.", showCustomerSupport: true })
                            setIsSubmitDisabled(false);
                        }
                        setWaiting(false);
                        setSwitchButtonActive(true);
                    });
                }
                if (resp.status >= 400 && resp.status < 600) {
                    resp.json()
                        .then(() => {
                            onError({
                                isOpen: true,
                                infoText: "Die Auszahlung deines Pfands auf das von Dir angegebene Konto wurde vom Zahlungsanbieter leider abgelehnt. Bitte gibt die Auszahlungsdaten erneut ein oder setze Dich mit unserem Kundenservice in Verbindung.", 
                                showCustomerSupport: false, 
                            });
                            setWaiting(false);
                            setIsSubmitDisabled(false);
                            setSwitchButtonActive(true);
                        })
                        .catch(() => {
                            onError({ isOpen: true, infoText: "Hier ist etwas schief gelaufen, versuch es später noch einmal!", showCustomerSupport: false })
                            setWaiting(false);
                            setIsSubmitDisabled(false);
                            setSwitchButtonActive(true);
                        });
                }
            }).catch(() => {
                onError({ isOpen: true, infoText: "Hier ist etwas schief gelaufen, versuch es später noch einmal!", showCustomerSupport: false })
                setWaiting(false);
                setIsSubmitDisabled(false);
                setSwitchButtonActive(true);
            });
        }, 500);
    }

    const form = Form.useForm()[0];
    return <Form
        name="payment-form"
        form={form}
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
        }}
        onValuesChange={ () => {
            const valueEmail = form.getFieldValue('email');

            setPaypalEmail(valueEmail);

            const formIsValid = EmailValidator.validate(valueEmail);

            setIsSubmitDisabled(!formIsValid);
        } }
    >
        <Form.Item 
            name={["email"]}
            rules={[
                {
                    required: true,
                    type: 'email',
                    message: 'Bitte geben Sie eine gültige E-Mail an',
                },
            ]}
        >
            <div>
                <Input
                    className="payment-form-input"
                    placeholder="PayPal E-Mail"
                    disabled={waiting}
                    prefix={waiting ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : <img src={paypalIcon} alt="" />}
                />
                {waiting && <span style={{ color: "gray", marginTop: "5px" }}>Ihre Daten werden verarbeitet ...</span>}
            </div>
        </Form.Item>
        <Form.Item>
            <div>
                <Button
                    style={{
                        color: (isSubmitDisabled ? (waiting ? "#131313" : "#b0b0b0")  : "#131313"),
                        background: (isSubmitDisabled ? "#f4f4f4" : "#ff81c5"),
                    }}
                    shape="round"
                    className="btn-get-disbursement"
                    size="middle"
                    htmlType="submit"
                    disabled={isSubmitDisabled}
                    onClick={submitData}
                >
                    {waiting ? "Bitte warten ..." : "Pfand auszahlen"}
                </Button>
                <Divider />
            </div>
        </Form.Item>
    </Form>;
}
