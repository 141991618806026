import * as React from "react";
import { Button, Flex } from "antd";
import "./ScanFailure.css";

interface ScanFailureProps {
    messageText: string,
    buttonText: string,
    buttonCallback: () => void;
    navigateToPayment: (() => void) | null;
}

export const ScanFailure: React.FC<ScanFailureProps> = ({
    messageText,
    buttonText,
    buttonCallback,
    navigateToPayment,
}) => {
    return <Flex
        justify='center'
        align='center'
        className='failure overlay'
    >
        <Flex
            justify='center'
            align='top'
            className='message'
        >
            <Flex
                justify='space-between'
                align='left'
                vertical={true}
                style={{
                    maxWidth: '80%',
                }}
            >
                <Flex
                    vertical={true}
                >
                    <h1>Scan war nicht erfolgreich</h1>
                    <p>{messageText}</p>
                </Flex>
                <Flex
                    justify='center'
                >
                    <Button
                        style={{
                            width: navigateToPayment ? "60%" : "90%",
                        }}
                        shape="round"
                        className="btn-failure"
                        size="middle"
                        onClick={() => { buttonCallback(); }}
                    >{ buttonText }</Button>
                    {
                        navigateToPayment && <Button
                            style={{
                                width: "50%",
                            }}
                            shape="round"
                            className="btn-failure"
                            size="middle"
                            onClick={() => { navigateToPayment(); }}
                        >Zur Auzahlung</Button>
                    }
                </Flex>
            </Flex>
        </Flex>
    </Flex>
}
