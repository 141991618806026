import { globalConfig } from "../configuration/config";
import { AssetType, NewPointOfReturn, RefundType, ReusablePackaging } from "../types";

/**
 * load all PointOfReturn from the API
 *
 * @returns {Promise<any>} A promise resolving to the data
 */
async function listPointOfReturn(): Promise<any> {
    const apiUrl = globalConfig.get().apiUrl

    return fetch(`${apiUrl}/locations`)
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
}

/**
 * Fetches a single point of return from the API
 *
 * @param {string} [porIdentifier] A specific por identifier to filter the point of return by.
 * @returns {Promise<NewPointOfReturn>} A promise resolving to the data of the point of return retrieved from the API.
 */
async function getPointOfReturn(porIdentifier: string): Promise<NewPointOfReturn|null> {
    return getEntity(porIdentifier, AssetType.PointOfReturn);
}

/**
 * Fetches a single reusable packaging from the API
 *
 * @param {string} [rpIdentifier] identifier of the reusable packaging
 * @returns {Promise<ReusablePackaging>} A promise resolving to the data of the reusable packaging retrieved from the API.
 */
async function getReusablePackaging(rpIdentifier: string): Promise<ReusablePackaging|null> {
    return getEntity(rpIdentifier, AssetType.ReusablePackaging);
}

async function getReusablePackagingFromGrai(rpGrai: string): Promise<ReusablePackaging|null> {
    const apiUrl = globalConfig.get().apiUrl

    return fetch(`${apiUrl}/grai/${rpGrai}`)
        .then((response) => {
            return response.ok ? response.json() : null;
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

/**
 * Posts information about returned assets to the API, along with payment details.
 *
 * @param {string} pointOfReturnId Identifier of the return station
 * @param {string[]} reusablePackagingIds List of ReusablePackaging Identifiers to be returned
 * @param {RefundType} refundType Currently the Type of refund, either PayPal or BankTransfer.
 * @param {string} refundTarget Currently the PayPal Account or IBAN to use for refunds.
 * @param {string} firstname The recipient firstname for this refund operation.
 * @param {string} lastname The recipient lastname for this refund operation.
 * @returns {Promise<any>} A promise resolving to the response from the API.
 */
async function postReturnSubmit(
    pointOfReturnId: string,
    reusablePackagingIds: string[],
    refundType: RefundType,
    refundTarget: string,
    firstname?: string,
    lastname?: string
): Promise<any> {
    const apiUrl = globalConfig.get().apiUrl

    return await fetch(`${apiUrl}/return-submit`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            pointOfReturn: pointOfReturnId,
            reusablePackagings: reusablePackagingIds,
            refund: {
                type: refundType,
                target: refundTarget,
                firstname: firstname,
                lastname: lastname,
            },
        }),
    });
}

async function getEntity(identifier: string, assetType: AssetType): Promise<any|null> {
    const apiUrl = globalConfig.get().apiUrl

    return fetch(`${apiUrl}/${assetType}/${identifier}`)
        .then((response) => {
            return response.ok ? response.json() : null;
        })
        .then((data) => {
            return data;
        })
        .catch(() => {
            return null;
        });
}

export {
    listPointOfReturn,
    getPointOfReturn,
    getReusablePackaging,
    getReusablePackagingFromGrai,
    postReturnSubmit,
};
