import { createBrowserRouter, redirectDocument, RouterProvider } from "react-router-dom";
import ContactPage from "./pages/ContactPage/ContactPage";
import ImprintPage from "./pages/ImprintPage/ImprintPage";
import LocationsPage from "./pages/LocationsPage/LocationsPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import ScanPage from "./pages/ScanPage/ScanPage";
import { ThankYouPage } from './pages/ThankYouPage/ThankYouPage';
import LandingPageMap from './pages/LandingPageMap/LandingPageMap';
import { PaymentPage } from './pages/PaymentPage/PaymentPage';
import Root from "./Root";

export default function App() {
    const retourePageUrl = 'https://www.multiloop.com/retoure?utm_source=qrcode&utm_medium=qrcode';
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Root />,
            children: [
                {
                    path: "",
                    element: <ScanPage />,
                },
                {
                    path: "return/rp/:rpId",
                    element: (<></>),
                    loader: () => redirectDocument(retourePageUrl),
                },
                {
                    // leading 0 before :grai needs to be removed in handling code if parameter is used,
                    // react router doesn't support partial segments in routes (`start/8003/0:grai` -> not possible)
                    path: "start/8003/:grai",
                    element: (<></>),
                    loader: () => redirectDocument(retourePageUrl),
                },
                {
                    path: "por/:porId",
                    element: <ScanPage />,
                },
                {
                    path: "contact",
                    element: <ContactPage />,
                },
                {
                    path: "imprint",
                    element: <ImprintPage />,
                },
                {
                    path: "map",
                    element: <LandingPageMap />,
                },
                {
                    path: "locations",
                    element: <LocationsPage />,
                },
                {
                    path: "privacy",
                    element: <PrivacyPage />,
                },
                {
                    path: "scan",
                    element: <ScanPage />,
                },
                {
                    path: "payment",
                    element: <PaymentPage />,
                },
                {
                    path: "thanks",
                    element: <ThankYouPage />,
                },
            ],
        },
    ]);

    return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}
