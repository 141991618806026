import * as React from 'react';
import { Button, Drawer, Flex, Modal, Table } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { EuroCircleOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import moment from 'moment-timezone';

import { ProgressHeader } from '../../components/ProgressHeader/ProgressHeader';
import { PaymentFormBanktransfer } from "../../components/PaymentForm/PaymentFormBanktransfer";
import { PaymentFormPaypal } from '../../components/PaymentForm/PaymentFormPaypal';
import FaqPage from '../../pages/FaqPage/FaqPage';

import { formatMoney } from "../../util/functions";
import { globalConfig } from "../../configuration/config";
import { PaymentData, PaymentErrorModalState, PaymentMethod, Step } from '../../types';

import boxImage from "../../assets/pages/scan/ml_box.png"
import triangleIcon from "../../assets/pages/payment/error_triangle.svg";
import paypalLogo from "../../assets/pages/payment/logo_paypal.png";
import briteLogo from "../../assets/pages/payment/logo_brite.png";

import "./Payment.css";

interface DataType {
    key: React.Key;
    name: string;
    value: string;
}

const initialErrorModalState: PaymentErrorModalState = {
    isOpen: false,
    infoText: "",
    showCustomerSupport: false,
};

export const PaymentPage = () => {
    const { state } = useLocation();
    const [faqPage, setFaqPage] = React.useState<React.ReactElement | null>(null);
    const [message, setMessage] = React.useState<React.ReactElement | null>(null);
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const [modalState, setModalState] = React.useState<PaymentErrorModalState>(initialErrorModalState);
    const paypalActive = globalConfig.get().paypalActive;
    const [paymentType, setPaymentType] = React.useState<PaymentMethod>(paypalActive ? PaymentMethod.PAYPAL : PaymentMethod.BANKTRANSFER);
    const [switchButtonActive, setSwitchButtonActive] = React.useState(paypalActive);
    const navigate = useNavigate();

    const depositReceipt = state.depositReceipt;

    const switchToFaqPage = () => {
        const onClose = () => {
            setFaqPage(null);
        };
        setFaqPage(<FaqPage onClose={onClose} />);
    };

    const togglePaymentMethod = () => {
        const type = paymentType === PaymentMethod.BANKTRANSFER ? PaymentMethod.PAYPAL : PaymentMethod.BANKTRANSFER;
        setPaymentType(type);
    };

    const columns: TableColumnsType<DataType> = [
        {
            title: '',
            dataIndex: 'name',
            render: (text: string) => <strong>{text}</strong>,
        },
        {
            title: '',
            dataIndex: 'value',
            render: (text: string) => <div style={{ float: 'right' }}>{text}</div>,
        },
    ];

    const data: DataType[] = [
        {
            key: '1',
            name: 'Anzahl Verpackungen',
            value: depositReceipt.numberOfBoxes.toString(),
        },
        {
            key: '2',
            name: 'Pfand',
            value: formatMoney(depositReceipt.depositAmount) + "*",
        },
        {
            key: '3',
            name: 'Rückgabe',
            value: depositReceipt.pointOfReturn,
        },
    ];

    const onGetDeposit = () => {
        setDrawerOpen(true);
    };

    const paymentData: PaymentData = {
        porId: depositReceipt.id,
        rpIds: depositReceipt.scannedBoxIds,
    };

    const paymentDrawerItems =
        paymentType === PaymentMethod.BANKTRANSFER
            ? {
                formComponent: <PaymentFormBanktransfer
                    paymentData={paymentData}
                    showMessage={setMessage}
                    showKdgToken={depositReceipt.pointOfReturn.toLowerCase().includes('kauf dich glücklich')}
                    onError={setModalState}
                    setSwitchButtonActive={setSwitchButtonActive}
                />,
                title: <div>Überweisung in Echtzeit <img src={briteLogo} alt="Brite" className="title-logo-brite" /></div>,
                description: "Trage deine IBAN für die Auszahlung ein und gib anschließend deine Verpackung zurück.",
                togglePaymentText: "Pfandrückzahlung mit",
                togglePaymentLogo: <img src={paypalLogo} alt="PayPal" className="logo-paypal" />,
            }
            : {
                formComponent: <PaymentFormPaypal
                    paymentData={paymentData}
                    showMessage={setMessage}
                    onError={setModalState}
                    showKdgToken={depositReceipt.pointOfReturn.toLowerCase().includes('kauf dich glücklich')}
                    setSwitchButtonActive={setSwitchButtonActive}
                />,
                title: <div>PayPal <img src={paypalLogo} alt="PayPal" className="title-logo-paypal" /></div>,
                description:
                "Trage deine Emailadresse für die Auszahlung ein und gib anschließend deine Verpackung zurück.",
                togglePaymentText: "Echtzeitüberweisung mit",
                togglePaymentLogo: <img src={briteLogo} alt="Brite" className="logo-brite" />,
            };

    return <Flex vertical align="center" className='scan-done'>
        <div className="heading">
            <ProgressHeader step={Step.PAYMENT} onClickFaq={switchToFaqPage} />
            <h1>Deine Rückgabe</h1>
            <p>{moment.utc(depositReceipt.createdAt).tz('Europe/Berlin').format('DD/MM/YYYY HH:mm')}</p>
        </div>
        <div style={{ marginTop: "0px", width: '100%', height: "100%" }}>
            <Flex justify='center' align='center' className='image-container'>
                <img className='box-img' alt='multiloop box' src={boxImage} />
            </Flex>
            <Flex vertical={true} justify='center' align='center' className='summary'>
                <Table className="payment-data" showHeader={false} columns={columns} dataSource={data} pagination={false} style={{ width: "90%" }} />
                <Flex style={{ width: "85vw" }}>
                    <Button
                        className="btn-scan-done"
                        onClick={() => navigate("/scan", { state: { depositReceipt: depositReceipt } })}
                        shape="round"
                        size="middle"
                    >Weitere Box scannen <PlusOutlined /></Button>
                </Flex>
                <Flex style={{ width: "85vw" }}>
                    <Button
                        className="btn-scan-done btn-disbursement"
                        shape="round"
                        size="middle"
                        onClick={() => onGetDeposit()}
                    >Pfand auszahlen <EuroCircleOutlined /></Button>
                </Flex>
                <Flex justify='center' align='center' style={{ marginTop: "15px", fontSize: "1.25em" }}>*Mwst. {formatMoney(depositReceipt.containedTax)}</Flex>
            </Flex>
        </div>
        <Drawer
            title={paymentDrawerItems.title}
            className='payment-card'
            placement='bottom'
            height='100%'
            rootClassName="payment-drawer"
            onClose={() => setDrawerOpen(false)}
            open={drawerOpen}
            styles={{
                header: {
                    borderBottom: '0',
                    padding: '20px 20px 6px',
                },
                body: {
                    padding: '6px 20px 48px',
                },
            }}
        >
            <span>{paymentDrawerItems.description}</span>
            {paymentDrawerItems.formComponent}
            <Flex vertical justify="flex-start" style={ { marginTop: "-10px" } }>
                <div className="alt">— Alternativ —</div>
                <Button
                    className="payment-switch"
                    type="link"
                    disabled={!switchButtonActive}
                    style={{ color: switchButtonActive ? "black" : "#b0b0b0" }}
                    onClick={() => togglePaymentMethod()}
                >
                    {paymentDrawerItems.togglePaymentText}
                    {paymentDrawerItems.togglePaymentLogo}
                    <RightOutlined />
                </Button>
            </Flex>
        </Drawer>
        {faqPage}
        {message}
        <Modal
            className="payment-error-modal"
            title="Hoppla!!"
            centered
            open={modalState.isOpen}
            footer=""
            onCancel={ () => {setModalState({ isOpen: false, infoText: "", showCustomerSupport: false }); } }
        >
            <Flex vertical>
                <p>{modalState.infoText}</p>
                <Flex justify="space-around">
                    <img src={triangleIcon} alt="error icon" />
                </Flex>
            </Flex>
        </Modal>
    </Flex>
}
