import * as React from "react";
import { Flex } from "antd";
import { Steps } from "antd";

import "./ProgressHeader.css"
import { FaqButton } from "../FaqButton/FaqButton";
import { Step } from "../../types";

interface ProgressHeaderProps {
    step: Step;
    onClickFaq: () => void;
}

export const ProgressHeader: React.FC<ProgressHeaderProps> = ({ step, onClickFaq }) => {
    return <Flex
        justify="center"
        style={{
            width: '100%',
        }}
    >
        <Steps
            className='steps'
            current={step}
            responsive={false}
            items={[
                {},
                {},
                {},
            ]}
        >
        </Steps>
        <FaqButton onClickFaq={onClickFaq}/>
    </Flex>
}
